import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, query, where,addDoc } from "firebase/firestore"; 
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, set, get } from "firebase/database"; 

const firebaseConfig = {
  apiKey: "AIzaSyABCLiL0HB_w2grKXW54nh49V_jBmyYLNM",
  authDomain: "snaped-dbe47.firebaseapp.com",
  projectId: "snaped-dbe47",
  storageBucket: "snaped-dbe47.firebasestorage.app",
  messagingSenderId: "705379377177",
  appId: "1:705379377177:web:508a11c248e5eb26040a88",
  measurementId: "G-V5KBE7KEH9"
};



const app = initializeApp(firebaseConfig);


const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);

export {
  auth,
  db,
  storage,
  database,
  storageRef,
  uploadBytes,
  getDownloadURL,
  ref,
  set,
  get,
  collection,    
  getDocs,
  query,
  where,
  addDoc
          
};
