import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Import BrowserRouter
import './App.css';
import Home from './Home';
import AboutPage from './components/About';
import ContributeButton from './components/Contribute';
import Navbar from './components/Navbar';
import EventComponent from './components/Event';
import SignInwithGoogle from './pages/Auth/signInWithGoogle';
import Profile from './pages/Auth/Profile';
import Register from './pages/Auth/Signup';
import Login from './pages/Auth/Login';
import { auth } from './components/firebase'; // Import Firebase auth
import ContactUS from './components/ContactUS';
import Sem1 from './pages/btech/cse/Sem1';
import Sem2 from './pages/btech/cse/Sem2';
import Sem3 from './pages/btech/cse/Sem3';
import Sem4 from './pages/btech/cse/Sem4';
import Sem5 from './pages/btech/cse/Sem5';
import AimlSem2 from './pages/btech/aiml/Sem2';
import AimlSem3 from './pages/btech/aiml/Sem3';
import AimlSem4 from './pages/btech/aiml/Sem4';
import AimlSem5 from './pages/btech/aiml/Sem5';
import AidsSem2 from './pages/btech/aids/Sem2';
import AidsSem3 from './pages/btech/aids/Sem3';
import AidsSem4 from './pages/btech/aids/Sem4';
import AidsSem5 from './pages/btech/aids/Sem5';
import ItSem1 from './pages/btech/it/Sem1';
import ItSem2 from './pages/btech/it/Sem2';
import ItSem3 from './pages/btech/it/Sem3';
import ItSem4 from './pages/btech/it/Sem4';
import ItSem5 from './pages/btech/it/Sem5';
import AppliedChemistry from './pages/notes/cse/Applied Chemistry';
import AppliedMathsII from './pages/notes/cse/Applied_Maths_II';
import AppliedPhysicsI from './pages/notes/cse/AppliedPhysicsI';
import AppliedMathsI from './pages/notes/cse/AppliedMathsI';
import CS from './pages/notes/cse/Communication_Skills';
import EM from './pages/notes/cse/Engineering_Mechanics';
import ES from './pages/notes/cse/Electrical_Science';
import EVS from './pages/notes/cse/Enviromental_Science';
import HVE from './pages/notes/cse/HVE';
import IC from './pages/notes/cse/Indian_Constitution';
import MP from './pages/notes/cse/Manufacturing_Process';
import PC from './pages/notes/cse/Programming_In_C';
import CM from './pages/notes/cse/CM';
import DLCD from './pages/notes/cse/DLCD';
import DM from './pages/notes/cse/DM';
import DS from './pages/notes/cse/DS';
import IKS from './pages/notes/cse/IKS';
import OOPS from './pages/notes/cse/OOPS';
import CD from './pages/notes/cse/CD';
import CN from './pages/notes/cse/CN';
import DAA from './pages/notes/cse/DAA';
import Economics from './pages/notes/cse/Economics';
import OS from './pages/notes/cse/OS';
import SE from './pages/notes/cse/SE';
import AI from './pages/notes/aiml/AI';
import FODS from './pages/notes/aiml/Fods';
import CR from './pages/notes/aiml/CR';
import PSLA from './pages/notes/aiml/Psla';
import TutorialCards from './components/post';
import TutorialDetail from './components/TutorialDetail';
import BackgroundAnimationTwo from './components/BackgroundAnimationTwo';
import AppliedPhysicsII from './pages/notes/cse/AppliedPhysics_II';
import Workshop from './pages/notes/cse/Workshop';
import EG from './pages/notes/cse/EG';
import CircuitsandSystems from './pages/notes/cse/Circuits and Systems';
import DBMS from './pages/notes/cse/DBMS';
import Java from './pages/notes/cse/ProgrammingInJava';
import PSLP from './pages/notes/cse/PSLP';
import TOC from './pages/notes/cse/TOC';
import TW from './pages/notes/cse/TW';
import DS_AI from './pages/notes/aiml/DS_AI';
import OOPS_AI from './pages/notes/aiml/OOPs_aiml';
import DBMS_AI from './pages/notes/aiml/Dbms_aiml';
import ETW from './pages/notes/aiml/ETW';
import SE_AI from './pages/notes/aiml/SE_aiml';
import CN_AI from './pages/notes/aiml/CN_aiml';
import ML from './pages/notes/aiml/ML';
import CM_AI from './pages/notes/aiml/CM_aiml';
import UHV_AI from './pages/notes/aiml/UHV-II';
import DataMining from './pages/notes/aids/DataMining';
import DLD_AI from './pages/notes/aiml/DL';
import PrivacyPolicy from './components/Privacypolicy';
import Footer from './components/Footer';
import TermsandConditon from './components/T&C';
import Feedback from './components/feedback';
import ScrollToTop from './components/ScrollToTop';
import { PlaneLanding } from 'lucide-react';
import LandingPage from './landing_page/LandingPage';
import About from './components/About';
import Blog from './components/Blog';

const PrivateRoute = ({ element, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/" replace={true} />
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Check authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user); // Set true if user is logged in
      setLoading(false); // Loading complete
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div class="flex items-center justify-center min-h-screen">
        <div
          class="mx-auto w-[90%] sm:w-[500px] bg-gray-950 rounded-xl overflow-hidden drop-shadow-xl"
        >
          <div class="bg-[#333] flex items-center p-[5px] text-white relative">
            <div class="flex absolute left-3">
              <span class="h-3.5 w-3.5 bg-[#ff605c] rounded-xl mr-2"></span>
              <span class="h-3.5 w-3.5 bg-[#ffbd44] rounded-xl mr-2"></span>
              <span class="h-3.5 w-3.5 bg-[#00ca4e] rounded-xl"></span>
            </div>
            <div class="flex-1 text-center text-white">status</div>
          </div>
          <div class="p-2.5 text-[#0f0]">
            <div>
              <span class="mr-2">Loading</span>
              <span class="animate-[ping_1.5s_0.5s_ease-in-out_infinite]">.</span>
              <span class="animate-[ping_1.5s_0.7s_ease-in-out_infinite]">.</span>
              <span class="animate-[ping_1.5s_0.9s_ease-in-out_infinite]">.</span>
            </div>
          </div>
        </div>
      </div>

    );
  }

  return (
    <Router>
      {<ScrollToTop />}
      {isAuthenticated && <Navbar />} {/* Show Navbar only when authenticated */}
      {isAuthenticated && <ContributeButton />} {/* Show Contribute button only when authenticated */}

      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/home" /> // Redirect to dashboard if signed in
            ) : (
              <LandingPage />
            )
          }
        />
        <Route path="/about" element={<About />} />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/signin" element={<SignInwithGoogle />} />

        {/* Private Routes */}
        <Route
          path="/home"
          element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />}
        />
        {/* <Route
          path="/about"
          element={<PrivateRoute element={<AboutPage />} isAuthenticated={isAuthenticated} />}
        /> */}
        <Route
          path="/event"
          element={<PrivateRoute element={<EventComponent />} isAuthenticated={isAuthenticated} />}
        />
        <Route path="/event/:id" element={<EventComponent />} />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/contactus"
          element={<PrivateRoute element={<ContactUS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/privacy-policy"
          element={<PrivateRoute element={<PrivacyPolicy />} isAuthenticated={isAuthenticated} />}
        />

        <Route
          path="/btech/cse/1st"
          element={<PrivateRoute element={<Sem1 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/2nd"
          element={<PrivateRoute element={<Sem2 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/3rd"
          element={<PrivateRoute element={<Sem3 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/4th"
          element={<PrivateRoute element={<Sem4 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/5th"
          element={<PrivateRoute element={<Sem5 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/1st"
          element={<PrivateRoute element={<Sem1 branch="AIML" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/2nd"
          element={<PrivateRoute element={<AimlSem2 branch="AIML" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/3rd"
          element={<PrivateRoute element={<AimlSem3 branch="AIML" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/4th"
          element={<PrivateRoute element={<AimlSem4 branch="AIML" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/5th"
          element={<PrivateRoute element={<AimlSem5 branch="AIML" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/1st"
          element={<PrivateRoute element={<Sem1 branch="AIDS" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/2nd"
          element={<PrivateRoute element={<AidsSem2 branch="AIDS" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/3rd"
          element={<PrivateRoute element={<AidsSem3 branch="AIDS" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/4th"
          element={<PrivateRoute element={<AidsSem4 branch="AIDS" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/5th"
          element={<PrivateRoute element={<AidsSem5 branch="AIDS" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/1st"
          element={<PrivateRoute element={<ItSem1 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/2nd"
          element={<PrivateRoute element={<ItSem2 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/3rd"
          element={<PrivateRoute element={<ItSem3 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/4th"
          element={<PrivateRoute element={<ItSem4 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/5th"
          element={<PrivateRoute element={<ItSem5 branch="CSE" />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AppliedChemistry"
          element={<PrivateRoute element={<AppliedChemistry />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AppliedMathII"
          element={<PrivateRoute element={<AppliedMathsII />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AppliedPhysicsI"
          element={<PrivateRoute element={<AppliedPhysicsI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AppliedMathI"
          element={<PrivateRoute element={<AppliedMathsI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CommunicationSkills"
          element={<PrivateRoute element={<CS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Engineering_Mechanics"
          element={<PrivateRoute element={<EM />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Electrical_Science"
          element={<PrivateRoute element={<ES />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Enviromental_Studies"
          element={<PrivateRoute element={<EVS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Human values and Ethics"
          element={<PrivateRoute element={<HVE />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Indian_Constitution"
          element={<PrivateRoute element={<IC />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Manufacturing_Process"
          element={<PrivateRoute element={<MP />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Programming_In_C"
          element={<PrivateRoute element={<PC />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Computational Methods"
          element={<PrivateRoute element={<CM />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Digital Logic and Circuit Design"
          element={<PrivateRoute element={<DLCD />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Discrete Mathematics"
          element={<PrivateRoute element={<DM />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/ Data Structures"
          element={<PrivateRoute element={<DS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Indian Knowledge System"
          element={<PrivateRoute element={<IKS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Object Oriented Programming"
          element={<PrivateRoute element={<OOPS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CD"
          element={<PrivateRoute element={<CD />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CN"
          element={<PrivateRoute element={<CN />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/DAA"
          element={<PrivateRoute element={<DAA />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Economics"
          element={<PrivateRoute element={<Economics />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/OS"
          element={<PrivateRoute element={<OS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/SE"
          element={<PrivateRoute element={<SE />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/AI"
          element={<PrivateRoute element={<AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/Fods"
          element={<PrivateRoute element={<FODS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/CR"
          element={<PrivateRoute element={<CR />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/Psla"
          element={<PrivateRoute element={<PSLA />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/Data Structure"
          element={<PrivateRoute element={<DS_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/OOPS"
          element={<PrivateRoute element={<OOPS_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/DBMS"
          element={<PrivateRoute element={<DBMS_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/ETW"
          element={<PrivateRoute element={<ETW />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/SE"
          element={<PrivateRoute element={<SE_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/CN"
          element={<PrivateRoute element={<CN_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/ML"
          element={<PrivateRoute element={<ML />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/CM"
          element={<PrivateRoute element={<CM_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/DLD_AI"
          element={<PrivateRoute element={<DLD_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/UHV-II"
          element={<PrivateRoute element={<UHV_AI />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/ai/Data Mining"
          element={<PrivateRoute element={<DataMining />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AppliedPhysicsII"
          element={<PrivateRoute element={<AppliedPhysicsII />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AppliedMathII"
          element={<PrivateRoute element={<AppliedMathsII />} isAuthenticated={isAuthenticated} />}
        />

        <Route
          path="/notes/cse/Workshop"
          element={<PrivateRoute element={<Workshop />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Engineering Graphics"
          element={<PrivateRoute element={<EG />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CircuitsandSystems"
          element={<PrivateRoute element={<CircuitsandSystems />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/DBMS"
          element={<PrivateRoute element={<DBMS />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Java"
          element={<PrivateRoute element={<Java />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/PSLP"
          element={<PrivateRoute element={<PSLP />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Theory of Computation"
          element={<PrivateRoute element={<TOC />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Technical Writing"
          element={<PrivateRoute element={<TW />} isAuthenticated={isAuthenticated} />}
        />

        <Route
          path="/blog/:postId"
          element={<PrivateRoute element={<Blog />} isAuthenticated={isAuthenticated} />}
        />

        <Route
          path="/blog"
          element={<PrivateRoute element={<Blog />} isAuthenticated={isAuthenticated} />}
        />

        <Route path="/tech-library" element={<PrivateRoute element={<TutorialCards />} isAuthenticated={isAuthenticated} />} />
        <Route path="/tutorial/:tutorialTitle" element={<TutorialDetail />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route
          path="/terms & condition"
          element={<TermsandConditon />}
        />

      </Routes>
      <BackgroundAnimationTwo />
    </Router>


  );
};

export default App;
