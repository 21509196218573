import { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Target, BookOpen, Flag } from 'lucide-react';
import Footer from './Footer';



const Privacypolicy = () => {
        const cards = [
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Information We Collect",
            description: "We may collect both personal and non-personal information to enhance your experience with SnapED codeCampus. Personal information includes details such as your name, email address, and phone number, which you may provide when registering, subscribing, or interacting with our website. Non-personal information encompasses data such as your device type, browser details, operating system, IP address, and browsing activity on our website. Additionally, we use cookies and other tracking technologies to analyze website traffic, improve user experience, and deliver relevant content. You have the option to manage your cookie preferences through your browser settings."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "How We Use Your Information",
            description: "The information we collect is used to provide and improve our services, communicate updates, promotions, and events, analyze website usage, and optimize content for our users. It also helps ensure the security of our website and prevent fraudulent activities. By understanding how our website is used, we can better serve our community and enhance our offerings."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Sharing Your Information",
            description: "We value your privacy and do not sell, trade, or rent your personal information to third parties. However, we may share your data with trusted service providers who assist in operating our website, provided they agree to keep the information confidential. We may also disclose your information when required by law or to protect the rights, safety, and privacy of SnapED codeCampus and its users."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Data Security",
            description: "To safeguard your data, we implement robust security measures designed to prevent unauthorized access, alteration, disclosure, or destruction of your personal information. While we strive to maintain the highest levels of security, it is important to note that no method of data transmission or storage is entirely foolproof, and we cannot guarantee absolute protection."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Your Rights",
            description: "As a valued user, you have rights regarding your personal data. You may access and update your information, request its deletion, opt out of promotional communications, and manage your cookie preferences through your browser settings. These options empower you to control your privacy and engagement with our platform."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Third-Party Links",
            description: "Our website may include links to third-party websites for additional resources or services. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies to understand how they handle your data before sharing any information."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Children’s Privacy",
            description: "SnapED codeCampus is not intended for individuals under the age of 13, and we do not knowingly collect personal information from children. If we become aware that a child has provided us with personal data, we will take prompt steps to delete the information from our records."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Policy Updates",
            description: "We reserve the right to update this Privacy Policy as needed. Any changes will be posted on this page with an updated effective date. We recommend reviewing this policy periodically to stay informed about how we protect your data and ensure your privacy."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Contact Information",
            description: "For any questions or concerns regarding these Terms, please contact SnapED CodeCampus at [ snaped4338@gmail.com ]."
          }
        ]
        
        



    const titleRef = useRef(null)

useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-title')
      }
    })
  })

  if (titleRef.current) {
    observer.observe(titleRef.current)
  }

  return () => observer.disconnect()
}, [])
  return (
    <>    <section className="min-h-[80vh] flex flex-col items-center justify-center text-center px-4 relative mb-[-8rem]">
    <div className="absolute inset-0 bg-gradient-radial from-blue-500/10 via-transparent to-transparent" />
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="backdrop-blur-sm bg-white/5 rounded-2xl p-6 md:p-10 max-w-4xl mx-auto"
    >
      <h1 ref={titleRef} className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight font-orbitron opacity-0">
      Privacy Policy SnapED <br />codeCampus
      </h1>
      <motion.p 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
        className="text-lg md:text-xl lg:text-2xl text-gray-300 max-w-2xl mx-auto font-light"
      >
        At SnapED codeCampus, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and protect the data you provide when you visit our website or use our services.
      </motion.p>
    </motion.div>
    <style jsx>{`
      @keyframes titleAnimation {
        0% { opacity: 0; transform: translateY(20px); }
        100% { opacity: 1; transform: translateY(0); }
      }
      .animate-title {
        animation: titleAnimation 1s forwards;
      }
    `}</style>
  </section>

  <section className="pt-4 pb-16 px-4">
              <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
                {cards.map((card, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2, duration: 0.8 }}
                    className="group relative overflow-hidden rounded-xl transition-all duration-500 hover:shadow-lg hover:shadow-blue-500/20"
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-500/30 to-purple-500/30 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="relative backdrop-blur-xl bg-white/10 p-6 h-full border border-white/10 group-hover:border-white/20 transition-colors">
                      <div className="mb-3 transform group-hover:scale-110 transition-transform">{card.icon}</div>
                      <h3 className="text-lg md:text-xl font-semibold mb-2 font-orbitron">{card.title}</h3>
                      <p className="text-sm md:text-base text-gray-300">{card.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </section>
            <Footer/>
            
  </>
  )
}

export default Privacypolicy