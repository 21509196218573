import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from './firebase';
import { collection, query, orderBy, onSnapshot, addDoc, doc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { 
  FacebookShareButton, 
  TwitterShareButton, 
  LinkedinShareButton,
  WhatsappShareButton
} from 'react-share';
import { 
  FacebookIcon, 
  TwitterIcon, 
  LinkedinIcon,
  WhatsappIcon
} from 'react-share';
import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Blog = () => {
  const { postId: urlPostId } = useParams();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [postType, setPostType] = useState('NEWS');
  const [customType, setCustomType] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [showAllPosts, setShowAllPosts] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const auth = getAuth();

  // Handle post selection and URL update
  const handlePostSelect = (post) => {
    setSelectedPost(post);
    navigate(`/blog/${post.id}`);
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      if (user && user.email === 'snaped4338@gmail.com') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
        setShowForm(false);
      }
    });

    const q = query(collection(db, 'blogPosts'), orderBy('createdAt', 'desc'));
    const unsubscribePosts = onSnapshot(q, (querySnapshot) => {
      const postsData = [];
      querySnapshot.forEach((doc) => {
        postsData.push({ id: doc.id, ...doc.data() });
      });
      setPosts(postsData);
      setFilteredPosts(postsData);
      
      // If there's a URL parameter, try to find that post
      if (urlPostId) {
        const postFromUrl = postsData.find(post => post.id === urlPostId);
        if (postFromUrl) {
          setSelectedPost(postFromUrl);
          return;
        }
      }
      
      // Otherwise, select the first post if none is selected
      if (postsData.length > 0 && !selectedPost) {
        handlePostSelect(postsData[0]);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribePosts();
    };
  }, [auth, urlPostId]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        post.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        post.type.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredPosts(filtered);
      if (filtered.length > 0 && !filtered.includes(selectedPost)) {
        handlePostSelect(filtered[0]);
      }
    }
  }, [searchQuery, posts]);

  const formatYouTubeUrl = (url) => {
    if (!url) return null;
    
    if (url.includes('youtube.com/embed')) return url;
    
    if (url.includes('youtube.com/live/')) {
      const videoId = url.split('youtube.com/live/')[1].split('?')[0];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    else if (url.includes('youtube.com/watch?v=')) {
      const videoId = url.split('v=')[1].split('&')[0];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    else if (url.includes('youtube.com/playlist?list=')) {
      const playlistId = url.split('list=')[1].split('&')[0];
      return `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
    }
    else if (url.includes('youtu.be/')) {
      const videoId = url.split('youtu.be/')[1].split('?')[0];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!title || !content) {
      toast.error('Please provide both a title and content for the post.');
      return;
    }

    const finalType = postType === 'CUSTOM' ? customType : postType;
    if (!finalType) {
      toast.error('Please provide a valid post type.');
      return;
    }

    let embedUrl = null;
    if (videoUrl) {
      embedUrl = formatYouTubeUrl(videoUrl);
      if (!embedUrl) {
        toast.error('Please provide a valid YouTube video, live stream, or playlist URL.');
        return;
      }
    }

    try {
      await addDoc(collection(db, 'blogPosts'), {
        title,
        content,
        videoUrl: embedUrl,
        type: finalType,
        createdAt: serverTimestamp(),
        author: auth.currentUser?.email || 'admin'
      });
      
      setTitle('');
      setContent('');
      setVideoUrl('');
      setPostType('NEWS');
      setCustomType('');
      setShowForm(false);
      toast.success('Post created successfully!');
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Failed to create post. Please try again.');
    }
  };

  const handleDeletePost = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deleteDoc(doc(db, 'blogPosts', postId));
        setPosts(posts.filter(post => post.id !== postId));
        if (selectedPost?.id === postId) {
          const remainingPosts = posts.filter(post => post.id !== postId);
          if (remainingPosts.length > 0) {
            handlePostSelect(remainingPosts[0]);
          } else {
            setSelectedPost(null);
            navigate('/blog');
          }
        }
        toast.success('Post deleted successfully!');
      } catch (error) {
        console.error('Error deleting document: ', error);
        toast.error('Failed to delete post. Please try again.');
      }
    }
  };

  const renderVideo = (url) => {
    if (!url) return null;
    return (
      <div className="relative pb-[56.25%] h-0 my-4 overflow-hidden rounded-lg">
        <iframe 
          src={url}
          className="absolute top-0 left-0 w-full h-full border-0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
          title="YouTube video player"
        />
      </div>
    );
  };

  const getPostUrl = (postId) => {
    return `${window.location.origin}/blog/${postId}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy link');
      });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4 md:mt-20">
        <div>
          <h1 className="text-3xl font-semibold text-[#F3F3E0] mb-2">Our Recent Posts</h1>
          <p className="text-[#F3F3E0]">Discover cutting-edge coding education and tech advancements at SnapEd CodeCampus</p>
        </div>
        <div className="w-full md:w-64">
          <input
            type="text"
            placeholder="Search posts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      
      <div className="border-t border-gray-200 my-6"></div>
      
      {isAdmin && (
        <div className="mb-8">
          <button 
            onClick={() => setShowForm(!showForm)}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded text-sm font-medium"
          >
            {showForm ? 'Cancel' : 'Add New Post'}
          </button>
          
          {showForm && (
            <form onSubmit={handleSubmit} className="bg-gray-50 p-6 rounded-lg mt-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Type:</label>
                <div className="flex gap-2">
                  <select 
                    value={postType}
                    onChange={(e) => setPostType(e.target.value)}
                    className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="NEWS">NEWS</option>
                    <option value="PRESS RELEASE">PRESS RELEASE</option>
                    <option value="CUSTOM">Custom Type</option>
                  </select>
                  {postType === 'CUSTOM' && (
                    <input
                      type="text"
                      value={customType}
                      onChange={(e) => setCustomType(e.target.value)}
                      placeholder="Enter custom type"
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  )}
                </div>
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Title:</label>
                <input 
                  type="text" 
                  value={title} 
                  onChange={(e) => setTitle(e.target.value)} 
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">YouTube Video URL:</label>
                <input 
                  type="url" 
                  value={videoUrl} 
                  onChange={(e) => setVideoUrl(e.target.value)} 
                  placeholder="https://www.youtube.com/watch?v=..."
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <p className="text-xs text-[#F3F3E0] mt-1">Supports videos, live streams, and playlists</p>
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-[#F3F3E0] mb-1">Content:</label>
                <textarea 
                  value={content} 
                  onChange={(e) => setContent(e.target.value)} 
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 min-h-[120px]"
                />
              </div>
              
              <button 
                type="submit"
                className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded text-sm font-medium"
              >
                Submit Post
              </button>
            </form>
          )}
        </div>
      )}
      
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main featured post (left side) */}
        <div className="lg:w-2/3">
          {selectedPost ? (
            <div className="backdrop-blur-xl bg-white/10 p-6 rounded-lg shadow-sm">
              <div className="flex justify-between items-center mb-2">
                <span className="font-bold text-sm text-blue-600">{selectedPost.type}</span>
                {isAdmin && (
                  <button 
                    onClick={() => handleDeletePost(selectedPost.id)}
                    className="text-red-600 hover:text-red-800 text-xl font-bold"
                  >
                    ×
                  </button>
                )}
              </div>
              
              <h2 className="text-2xl font-semibold text-[#F3F3E0] mb-4">{selectedPost.title}</h2>
              
              {renderVideo(selectedPost.videoUrl)}
              
              <div className="text-[#F3F3E0] mb-4 whitespace-pre-line break-words overflow-x-auto px-4 text-base md:text-lg">
                {selectedPost.content}
              </div>

              {/* Share buttons for main post */}
              <div className="flex items-center space-x-2 mt-6">
                <span className="text-sm text-[#F3F3E0]">Share:</span>
                <FacebookShareButton 
                  url={getPostUrl(selectedPost.id)} 
                  quote={`${selectedPost.title} - ${selectedPost.content.substring(0, 100)}...`}
                  hashtag="#Skillines"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton 
                  url={getPostUrl(selectedPost.id)} 
                  title={selectedPost.title}
                  via="Skillines"
                  hashtags={['Skillines']}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton 
                  url={getPostUrl(selectedPost.id)} 
                  title={selectedPost.title}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <LinkedinShareButton 
                  url={getPostUrl(selectedPost.id)} 
                  title={selectedPost.title}
                  summary={selectedPost.content.substring(0, 200)}
                  source="Skillines"
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <button 
                  onClick={() => copyToClipboard(getPostUrl(selectedPost.id))}
                  className="p-1 rounded-full hover:bg-[#79D7BE] transition-colors"
                  title="Copy link"
                  aria-label="Copy link"
                >
                  <FiCopy className="text-gray-600" size={20} />
                </button>
              </div>
              
              <div className="border-t border-gray-200 mt-6"></div>
            </div>
          ) : (
            <div className="backdrop-blur-xl bg-white/10 p-6 rounded-lg shadow-sm text-center text-[#F3F3E0]">
              {filteredPosts.length === 0 ? 'No matching posts found' : 'No posts available'}
            </div>
          )}
        </div>
        
        {/* Sidebar with recent posts (right side) */}
        <div className="lg:w-1/3">
          <div className="backdrop-blur-xl bg-white/10 p-6 rounded-lg shadow-sm sticky top-8">
            <h3 className="text-lg font-semibold text-[#F3F3E0] mb-4">Recent Posts</h3>
            <div className="space-y-4">
              {(showAllPosts ? filteredPosts : filteredPosts.slice(0, 4)).map((post) => (
                <div 
                  key={post.id} 
                  onClick={() => handlePostSelect(post)}
                  className={`p-3 rounded cursor-pointer transition-colors ${selectedPost?.id === post.id ? 'bg-blue-50 border-l-4 border-blue-500' : 'hover:bg-gray-50'}`}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <span className="block text-xs font-medium text-blue-600 mb-1">{post.type}</span>
                      <h4 className="text-md font-medium text-[#79D7BE] mb-1">{post.title}</h4>
                      <p className="text-sm text-[#79D7BE] line-clamp-2">
                        {post.content.split('\n')[0]}
                      </p>
                    </div>
                    {/* Mini share button for sidebar posts */}
                    <div className="flex space-x-1 ml-2">
                      <WhatsappShareButton 
                        url={getPostUrl(post.id)} 
                        title={post.title}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <WhatsappIcon size={24} round />
                      </WhatsappShareButton>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(getPostUrl(post.id));
                        }}
                        className="p-1 rounded-full hover:bg-gray-100 transition-colors"
                        title="Copy link"
                        aria-label="Copy link"
                      >
                        <FiCopy className="text-[#79D7BE]" size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              
              {filteredPosts.length > 4 && (
                <button
                  onClick={() => setShowAllPosts(!showAllPosts)}
                  className="w-full text-center text-blue-600 hover:text-blue-800 text-sm font-medium mt-2"
                >
                  {showAllPosts ? 'Show Less' : 'Show More'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;