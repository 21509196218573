// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom'; 
// import logo from '../assets/LOGO SnapED.png';
// import { Button } from "@heroui/react";
// import HeroSection from './HeroSection';
// import Footer from '../components/Footer';
// import Socialicon from '../components/Socialicon';
// import Feedback from '../components/feedback';

// const LandingPage = () => {
//   const [isMenuOpen, setIsMenuOpen] = React.useState(false);
//   const navigate = useNavigate();

//   const menuItems = [
//     { name: "Home", path: "/" },
//     { name: "About", path: "/about" },
//     { name: "Sign In", path: "/login" },
//     { name: "Sign Up", path: "/signup" },
//   ];

//   const handleNavigation = (path) => {
//     navigate(path);
//     setIsMenuOpen(false); // Close mobile menu after navigation
//   };

//   return (
//     <div>
//       <header className="flex justify-between items-center p-2 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-sm bg-[rgba(255,255,255,0.1)] border border-[rgba(255,255,255,0.1)]">
//         <div className="flex items-center">
//           <img src={logo} alt="SnapED logo" className="mr-2 w-[150px]" />
//         </div>

//         {/* Desktop Navigation */}
//         <nav className="hidden md:flex space-x-6">
//           <Link to="/" className="bg-gradient-to-r from-white to-white bg-clip-text text-transparent hover:from-cyan-500 hover:to-green-400">Home</Link>
//           <Link to="/about" className="bg-gradient-to-r from-white to-white bg-clip-text text-transparent hover:from-cyan-500 hover:to-green-400">About</Link>
//         </nav>

//         {/* Mobile Menu Toggle */}
//         <button
//           className="md:hidden p-2 focus:outline-none"
//           onClick={() => setIsMenuOpen(!isMenuOpen)}
//         >
//           <svg
//             className="w-6 h-6"
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M4 6h16M4 12h16m-7 6h7"
//             />
//           </svg>
//         </button>

//         {/* Desktop Buttons */}
//         <div className="hidden md:flex space-x-4">
//           <Button
//             radius="md"
//             className="bg-gradient-to-r from-cyan-500 to-green-400 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-all duration-200 active:scale-95"
//             onClick={() => handleNavigation('/login')}
//           >
//             Sign In
//           </Button>
//           <Button
//             radius="md"
//             className="bg-gradient-to-r from-cyan-500 to-green-400 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-all duration-200 active:scale-95"
//             onClick={() => handleNavigation('/signup')}
//           >
//             Sign Up
//           </Button>
//         </div>

//         {/* Mobile Menu */}
//         {isMenuOpen && (
//           <div className="md:hidden absolute top-16 right-6 bg-[#27548A] border border-[rgba(255,255,255,0.1)] rounded-lg p-4">
//             {menuItems.map((item, index) => (
//               <button
//                 key={index}
//                 onClick={() => handleNavigation(item.path)}
//                 className="block w-full text-left px-4 py-2 hover:bg-gradient-to-r from-cyan-500 to-green-400 rounded"
//               >
//                 {item.name}
//               </button>
//             ))}
//           </div>
//         )}
//       </header>
//       <HeroSection/>
//       <Feedback/>
//       <Socialicon/>
//       <Footer/>
//     </div>
//   );
// };

// export default LandingPage;


import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { motion, AnimatePresence } from "framer-motion";
import logo from '../assets/LOGO SnapED.png';
import { Button } from "@heroui/react";
import HeroSection from './HeroSection';
import Footer from '../components/Footer';
import Socialicon from '../components/Socialicon';
import Feedback from '../components/feedback';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [hoveredItem, setHoveredItem] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(null);
  const navigate = useNavigate();

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Sign In", path: "/login" },
    { name: "Sign Up", path: "/signup" },
  ];

  const handleNavigation = (path, index) => {
    setActiveItem(index);
    setTimeout(() => {
      navigate(path);
      setIsMenuOpen(false);
      setActiveItem(null);
    }, 150); // Short delay to show the click effect
  };

  // Animation variants
  const menuVariants = {
    hidden: { 
      opacity: 0,
      y: -20,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: {
        duration: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
    hover: { 
      scale: 1.05,
      transition: { type: "spring", stiffness: 400, damping: 10 }
    },
    tap: { 
      scale: 0.95,
      backgroundColor: "rgba(74, 222, 128, 0.2)",
      transition: { duration: 0.1 }
    }
  };

  const hoverIndicatorVariants = {
    hidden: { opacity: 0, width: 0 },
    visible: (i) => ({
      opacity: 1,
      width: "100%",
      transition: { duration: 0.3, delay: i * 0.1 }
    })
  };

  const clickEffectVariants = {
    active: {
      scale: 0.95,
      backgroundColor: "rgba(74, 222, 128, 0.3)",
      transition: { duration: 0.1 }
    },
    inactive: {
      scale: 1,
      backgroundColor: "transparent",
      transition: { duration: 0.2 }
    }
  };

  return (
    <div>
      <header className="flex justify-between items-center p-2 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-sm bg-[rgba(255,255,255,0.1)] border border-[rgba(255,255,255,0.1)]">
        <div className="flex items-center">
          <img src={logo} alt="SnapED logo" className="mr-2 w-[150px]" />
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          <Link to="/" className="bg-gradient-to-r from-white to-white bg-clip-text text-transparent hover:from-cyan-500 hover:to-green-400">Home</Link>
          <Link to="/about" className="bg-gradient-to-r from-white to-white bg-clip-text text-transparent hover:from-cyan-500 hover:to-green-400">About</Link>
        </nav>

        {/* Mobile Menu Toggle */}
        <motion.button
          className="md:hidden p-2 focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          whileTap={{ scale: 0.95 }}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </motion.button>

        {/* Desktop Buttons */}
        <div className="hidden md:flex space-x-4">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              radius="md"
              className="bg-gradient-to-r from-cyan-500 to-green-400 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-all duration-200 active:scale-95"
              onClick={() => handleNavigation('/login', 2)}
            >
              Sign In
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              radius="md"
              className="bg-gradient-to-r from-cyan-500 to-green-400 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-all duration-200 active:scale-95"
              onClick={() => handleNavigation('/signup', 3)}
            >
              Sign Up
            </Button>
          </motion.div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className="md:hidden absolute top-16 right-6 bg-[#27548A] border border-[rgba(255,255,255,0.1)] rounded-lg p-4 z-50 shadow-lg"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={menuVariants}
            >
              <div className="relative">
                {menuItems.map((item, index) => (
                  <motion.div 
                    key={index}
                    className="relative overflow-hidden py-2"
                    onMouseEnter={() => setHoveredItem(index)}
                    onMouseLeave={() => setHoveredItem(null)}
                    variants={itemVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <motion.button
                      onClick={() => handleNavigation(item.path, index)}
                      className="relative z-10 w-full text-left px-4 py-2 text-white rounded flex items-center"
                      animate={activeItem === index ? "active" : "inactive"}
                      variants={clickEffectVariants}
                    >
                      {item.name}
                      {activeItem === index && (
                        <motion.span 
                          className="ml-2"
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          exit={{ scale: 0 }}
                          transition={{ type: "spring", stiffness: 500 }}
                        >
                          ✓
                        </motion.span>
                      )}
                    </motion.button>
                    
                    {/* Hover indicator */}
                    <motion.div
                      className="absolute bottom-0 left-0 h-full bg-gradient-to-r from-cyan-500 to-green-400 rounded"
                      variants={hoverIndicatorVariants}
                      custom={index}
                      initial="hidden"
                      animate={hoveredItem === index ? "visible" : "hidden"}
                    />
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </header>
      <HeroSection/>
      <Feedback/>
      <Socialicon/>
      <Footer/>
    </div>
  );
};

export default LandingPage;