// // import React, { useState, useEffect } from "react";
// // import { useNavigate, useParams } from "react-router-dom";
// // import { db } from "./firebase"; // Ensure Firebase is configured
// // import { collection, doc, getDocs, getDoc, addDoc } from "firebase/firestore";
// // import { motion } from "framer-motion";
// // import { FaShareAlt } from "react-icons/fa";

// // const EventComponent = () => {
// //   const [events, setEvents] = useState([]);
// //   const [event, setEvent] = useState(null);
// //   const [newEvent, setNewEvent] = useState({
// //     title: "",
// //     description: "",
// //     date: "",
// //     thumbnail: "",  // Changed from imageUrl to thumbnail
// //     registrationLink: "",
// //   });
  

// //   const { id } = useParams(); // Get event ID from URL (if exists)
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     const fetchEvents = async () => {
// //       const eventsCollection = collection(db, "events");
// //       const eventSnapshot = await getDocs(eventsCollection);
// //       const eventList = eventSnapshot.docs.map((doc) => ({
// //         id: doc.id,
// //         ...doc.data(),
// //       }));
// //       setEvents(eventList);
// //     };

// //     fetchEvents();
// //   }, []);

// //   useEffect(() => {
// //     if (id) {
// //       const fetchEventDetails = async () => {
// //         const docRef = doc(db, "events", id);
// //         const docSnap = await getDoc(docRef);

// //         if (docSnap.exists()) {
// //           setEvent({ id: docSnap.id, ...docSnap.data() });
// //         }
// //       };

// //       fetchEventDetails();
// //     }
// //   }, [id]);

// //   const handleShare = async () => {
// //     const eventURL = `${window.location.origin}/event/${id}`;

// //     if (navigator.share) {
// //       try {
// //         await navigator.share({
// //           title: event.title,
// //           text: `Check out this event: ${event.title}`,
// //           url: eventURL,
// //         });
// //       } catch (error) {
// //         console.error("Error sharing:", error);
// //       }
// //     } else {
// //       navigator.clipboard.writeText(eventURL);
// //       alert("Link copied to clipboard!");
// //     }
// //   };

// //   const handleThumbnailUpload = (file) => {
// //     return new Promise((resolve, reject) => {
// //       const reader = new FileReader();
// //       reader.onloadend = () => resolve(reader.result);
// //       reader.onerror = reject;
// //       reader.readAsDataURL(file);
// //     });
// //   };

// //   const handleAddEvent = async () => {
// //     if (!newEvent.title || !newEvent.date) {
// //       alert("Title and Date are required!");
// //       return;
// //     }
  
// //     try {
// //       await addDoc(collection(db, "events"), newEvent);
// //       alert("Event added successfully!");
// //       setNewEvent({
// //         title: "",
// //         description: "",
// //         date: "",
// //         thumbnail: "",  // Reset thumbnail instead of imageUrl
// //         registrationLink: "",
// //       });
// //     } catch (error) {
// //       console.error("Error adding event:", error);
// //     }
// //   };

// //   const handleFileChange = async (e) => {
// //     const file = e.target.files[0];
// //     if (file) {
// //       const base64 = await handleThumbnailUpload(file);
// //       setNewEvent({ ...newEvent, thumbnail: base64 });
// //     }
// //   };
  

// //   if (id && event) {
// //     return (
// //       <div className="flex items-center justify-center min-h-screen w-full bg-gray-900 text-white">
// //       <div className="w-full h-screen bg-gray-800 p-6 rounded-xl shadow-lg flex flex-col md:flex-row overflow-hidden">
// //         {/* Left Side: Image */}
// //         <div className="w-full md:w-1/2 h-full flex-shrink-0">
// //           <img
// //             src={event.thumbnail || "https://via.placeholder.com/600"}
// //             alt={event.title}
// //             className="w-full h-full object-cover rounded-lg shadow-md"
// //           />
// //         </div>

// //         {/* Right Side: Content */}
// //         <div className="md:w-1/2 h-full p-6 flex flex-col justify-center">
// //           <h2 className="text-5xl font-bold text-blue-400">{event.title}</h2>
// //           <p className="text-gray-300 mt-4 text-xl">{event.description}</p>
// //           <p className="text-gray-400 mt-2 text-lg">📅 {event.date}</p>

// //           <div className="flex flex-col sm:flex-row items-center justify-start gap-6 mt-8">
// //             <a
// //               href={event.registrationLink}
// //               target="_blank"
// //               rel="noopener noreferrer"
// //               className="bg-blue-500 text-white px-8 py-4 rounded-lg shadow-md hover:bg-blue-600 transition-all text-lg"
// //             >
// //               Register Now
// //             </a>

// //             <button
// //               onClick={handleShare}
// //               className="flex items-center justify-center gap-3 bg-gray-700 px-6 py-4 rounded-lg hover:bg-gray-600 transition-all text-lg"
// //             >
// //               <FaShareAlt className="text-white text-2xl" />
// //               <span>Share</span>
// //             </button>
// //           </div>

// //           <button
// //             onClick={() => navigate("/event")}
// //             className="mt-8 w-full bg-gray-700 text-white px-6 py-4 rounded-lg hover:bg-gray-600 transition-all text-lg"
// //           >
// //             Back to Events
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// //   }

// //   return (
// //     <div className="p-6 mt-[100px]">
// //       <h2 className="text-2xl font-bold text-center mb-6">Upcoming Events</h2>
      
// //       <div className="mb-6 border p-4 rounded-lg shadow-md">
// //         <h3 className="text-xl font-semibold mb-3">Add New Event</h3>
// //         <input type="text" placeholder="Event Title" className="border p-2 rounded w-full mb-2" value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />
// //         <input type="text" placeholder="Description" className="border p-2 rounded w-full mb-2" value={newEvent.description} onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })} />
// //         <input type="date" className="border p-2 rounded w-full mb-2" value={newEvent.date} onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })} />
// //         <input type="file" accept="image/*" className="border p-2 rounded w-full mb-2" onChange={handleFileChange} />

// //         <input type="text" placeholder="Registration Link" className="border p-2 rounded w-full mb-2" value={newEvent.registrationLink} onChange={(e) => setNewEvent({ ...newEvent, registrationLink: e.target.value })} />
// //         <button onClick={handleAddEvent} className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">Add Event</button>
// //       </div>
      
// //       <div className="flex overflow-x-auto space-x-6 p-4 scrollbar-thin scrollbar-thumb-blue-300 scrollbar-track-transparent">
// //         {events.map((event) => (
// //           <motion.div
// //             key={event.id}
// //             whileHover={{ scale: 1.08, rotate: 2 }}
// //             whileTap={{ scale: 0.98 }}
// //             className="backdrop-blur-lg bg-white/10 shadow-xl rounded-lg p-4 cursor-pointer w-72 border border-white/20 transition-all"
// //             onClick={() => navigate(`/event/${event.id}`)}
// //           >
// //             <img
// //               src={event.thumbnail || "https://via.placeholder.com/300"}
// //               alt={event.title}
// //               className="w-full h-48 object-cover rounded-lg"
// //             />
// //             <h3 className="text-lg font-semibold text-white mt-3">{event.title}</h3>
// //             <p className="text-sm text-gray-200">{event.date}</p>
// //           </motion.div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default EventComponent;

// // // import React, { useState, useEffect } from "react";
// // // import { useNavigate, useParams } from "react-router-dom";
// // // import { db } from "./FirebaseTwo"; // Ensure Firebase is configured
// // // import { collection, doc, getDocs, getDoc, addDoc } from "firebase/firestore";
// // // import { motion } from "framer-motion";
// // // import { FaShareAlt } from "react-icons/fa";

// // // const EventComponent = () => {
// // //   const [events, setEvents] = useState([]);
// // //   const [event, setEvent] = useState(null);
// // //   const [newEvent, setNewEvent] = useState({
// // //     title: "",
// // //     description: "",
// // //     date: "",
// // //     thumbnail: "", // Changed from imageUrl to thumbnail
// // //     registrationLink: "",
// // //   });

// // //   const { id } = useParams(); // Get event ID from URL (if exists)
// // //   const navigate = useNavigate();

// // //   useEffect(() => {
// // //     const fetchEvents = async () => {
// // //       const eventsCollection = collection(db, "events");
// // //       const eventSnapshot = await getDocs(eventsCollection);
// // //       const eventList = eventSnapshot.docs.map((doc) => ({
// // //         id: doc.id,
// // //         ...doc.data(),
// // //       }));
// // //       setEvents(eventList);
// // //     };

// // //     fetchEvents();
// // //   }, []);

// // //   useEffect(() => {
// // //     if (id) {
// // //       const fetchEventDetails = async () => {
// // //         const docRef = doc(db, "events", id);
// // //         const docSnap = await getDoc(docRef);

// // //         if (docSnap.exists()) {
// // //           setEvent({ id: docSnap.id, ...docSnap.data() });
// // //         }
// // //       };

// // //       fetchEventDetails();
// // //     }
// // //   }, [id]);

// // //   const handleShare = async () => {
// // //     const eventURL = `${window.location.origin}/event/${id}`;

// // //     if (navigator.share) {
// // //       try {
// // //         await navigator.share({
// // //           title: event.title,
// // //           text: `Check out this event: ${event.title}`,
// // //           url: eventURL,
// // //         });
// // //       } catch (error) {
// // //         console.error("Error sharing:", error);
// // //       }
// // //     } else {
// // //       navigator.clipboard.writeText(eventURL);
// // //       alert("Link copied to clipboard!");
// // //     }
// // //   };

// // //   const handleThumbnailUpload = (file) => {
// // //     return new Promise((resolve, reject) => {
// // //       const reader = new FileReader();
// // //       reader.onloadend = () => resolve(reader.result);
// // //       reader.onerror = reject;
// // //       reader.readAsDataURL(file);
// // //     });
// // //   };

// // //   const handleAddEvent = async () => {
// // //     if (!newEvent.title || !newEvent.date) {
// // //       alert("Title and Date are required!");
// // //       return;
// // //     }

// // //     try {
// // //       await addDoc(collection(db, "events"), newEvent);
// // //       alert("Event added successfully!");
// // //       setNewEvent({
// // //         title: "",
// // //         description: "",
// // //         date: "",
// // //         thumbnail: "", // Reset thumbnail instead of imageUrl
// // //         registrationLink: "",
// // //       });
// // //     } catch (error) {
// // //       console.error("Error adding event:", error);
// // //     }
// // //   };

// // //   const handleFileChange = async (e) => {
// // //     const file = e.target.files[0];
// // //     if (file) {
// // //       const base64 = await handleThumbnailUpload(file);
// // //       setNewEvent({ ...newEvent, thumbnail: base64 });
// // //     }
// // //   };

// // //   if (id && event) {
// // //     return (
// // //       <div className="flex items-center justify-center min-h-screen w-full bg-gray-900 text-white md:mt-20"> {/* Added md:mt-20 for desktop screens */}
// // //         <div className="w-full h-full bg-gray-800 p-6 rounded-xl shadow-lg flex flex-col md:flex-row overflow-hidden">
// // //           {/* Left Side: Image */}
// // //           <div className="w-full md:w-1/2 h-64 md:h-auto flex-shrink-0">
// // //             <img
// // //               src={event.thumbnail || "https://via.placeholder.com/600"}
// // //               alt={event.title}
// // //               className="w-full h-full object-cover rounded-lg shadow-md"
// // //             />
// // //           </div>

// // //           {/* Right Side: Content */}
// // //           <div className="w-full md:w-1/2 h-full p-6 flex flex-col justify-center">
// // //             <h2 className="text-3xl md:text-5xl font-bold text-blue-400">{event.title}</h2>
// // //             <p className="text-gray-300 mt-4 text-lg md:text-xl">{event.description}</p>
// // //             <p className="text-gray-400 mt-2 text-base md:text-lg">📅 {event.date}</p>

// // //             <div className="flex flex-col sm:flex-row items-center justify-start gap-4 md:gap-6 mt-8">
// // //               <a
// // //                 href={event.registrationLink}
// // //                 target="_blank"
// // //                 rel="noopener noreferrer"
// // //                 className="bg-blue-500 text-white px-6 py-3 md:px-8 md:py-4 rounded-lg shadow-md hover:bg-blue-600 transition-all text-base md:text-lg"
// // //               >
// // //                 Register Now
// // //               </a>

// // //               <button
// // //                 onClick={handleShare}
// // //                 className="flex items-center justify-center gap-2 md:gap-3 bg-gray-700 px-4 py-3 md:px-6 md:py-4 rounded-lg hover:bg-gray-600 transition-all text-base md:text-lg"
// // //               >
// // //                 <FaShareAlt className="text-white text-xl md:text-2xl" />
// // //                 <span>Share</span>
// // //               </button>
// // //             </div>

// // //             <button
// // //               onClick={() => navigate("/event")}
// // //               className="mt-8 w-full bg-gray-700 text-white px-6 py-3 md:px-6 md:py-4 rounded-lg hover:bg-gray-600 transition-all text-base md:text-lg"
// // //             >
// // //               Back to Events
// // //             </button>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     );
// // //   }

// // //   return (
// // //     <div className="p-6 mt-[100px]">
// // //       <h2 className="text-2xl font-bold text-center mb-6">Upcoming Events</h2>

// // //       <div className="mb-6 border p-4 rounded-lg shadow-md">
// // //         <h3 className="text-xl font-semibold mb-3">Add New Event</h3>
// // //         <input
// // //           type="text"
// // //           placeholder="Event Title"
// // //           className="border p-2 rounded w-full mb-2"
// // //           value={newEvent.title}
// // //           onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
// // //         />
// // //         <input
// // //           type="text"
// // //           placeholder="Description"
// // //           className="border p-2 rounded w-full mb-2"
// // //           value={newEvent.description}
// // //           onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
// // //         />
// // //         <input
// // //           type="date"
// // //           className="border p-2 rounded w-full mb-2"
// // //           value={newEvent.date}
// // //           onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
// // //         />
// // //         <input
// // //           type="file"
// // //           accept="image/*"
// // //           className="border p-2 rounded w-full mb-2"
// // //           onChange={handleFileChange}
// // //         />

// // //         <input
// // //           type="text"
// // //           placeholder="Registration Link"
// // //           className="border p-2 rounded w-full mb-2"
// // //           value={newEvent.registrationLink}
// // //           onChange={(e) => setNewEvent({ ...newEvent, registrationLink: e.target.value })}
// // //         />
// // //         <button
// // //           onClick={handleAddEvent}
// // //           className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
// // //         >
// // //           Add Event
// // //         </button>
// // //       </div>

// // //       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4">
// // //         {events.map((event) => (
// // //           <motion.div
// // //             key={event.id}
// // //             whileHover={{ scale: 1.05 }}
// // //             whileTap={{ scale: 0.95 }}
// // //             className="backdrop-blur-lg bg-white/10 shadow-xl rounded-lg p-4 cursor-pointer w-full border border-white/20 transition-all"
// // //             onClick={() => navigate(`/event/${event.id}`)}
// // //           >
// // //             <div className="w-full h-48 md:h-56 lg:h-64 overflow-hidden rounded-lg">
// // //               <img
// // //                 src={event.thumbnail || "https://via.placeholder.com/300"}
// // //                 alt={event.title}
// // //                 className="w-full h-full object-cover"
// // //               />
// // //             </div>
// // //             <h3 className="text-lg font-semibold text-white mt-3">{event.title}</h3>
// // //             <p className="text-sm text-gray-200">{event.date}</p>
// // //           </motion.div>
// // //         ))}
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default EventComponent;




// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { db, auth } from "./firebase";
// import { collection, doc, getDocs, getDoc, addDoc } from "firebase/firestore";
// import { motion } from "framer-motion";
// import { FaShareAlt, FaArrowLeft } from "react-icons/fa";

// const EventComponent = () => {
//   const [events, setEvents] = useState([]);
//   const [event, setEvent] = useState(null);
//   const [newEvent, setNewEvent] = useState({
//     title: "",
//     description: "",
//     date: "",
//     thumbnail: "",
//     registrationLink: "",
//   });
//   const [currentUser, setCurrentUser] = useState(null);
//   const [isAdmin, setIsAdmin] = useState(false);

//   const { id } = useParams();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       setCurrentUser(user);
//       setIsAdmin(user && user.email === "snaped4338@gmail.com");
//     });

//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       const eventsCollection = collection(db, "events");
//       const eventSnapshot = await getDocs(eventsCollection);
//       const eventList = eventSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setEvents(eventList);
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     if (id) {
//       const fetchEventDetails = async () => {
//         const docRef = doc(db, "events", id);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           setEvent({ id: docSnap.id, ...docSnap.data() });
//         }
//       };

//       fetchEventDetails();
//     }
//   }, [id]);

//   const handleShare = async () => {
//     const eventURL = `${window.location.origin}/event/${id}`;

//     if (navigator.share) {
//       try {
//         await navigator.share({
//           title: event.title,
//           text: `Check out this event: ${event.title}`,
//           url: eventURL,
//         });
//       } catch (error) {
//         console.error("Error sharing:", error);
//       }
//     } else {
//       navigator.clipboard.writeText(eventURL);
//       alert("Link copied to clipboard!");
//     }
//   };

//   const handleThumbnailUpload = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(file);
//     });
//   };

//   const handleAddEvent = async () => {
//     if (!newEvent.title || !newEvent.date) {
//       alert("Title and Date are required!");
//       return;
//     }

//     try {
//       await addDoc(collection(db, "events"), newEvent);
//       alert("Event added successfully!");
//       setNewEvent({
//         title: "",
//         description: "",
//         date: "",
//         thumbnail: "",
//         registrationLink: "",
//       });
//       // Refresh events list
//       const eventsCollection = collection(db, "events");
//       const eventSnapshot = await getDocs(eventsCollection);
//       const eventList = eventSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setEvents(eventList);
//     } catch (error) {
//       console.error("Error adding event:", error);
//     }
//   };

//   const handleFileChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const base64 = await handleThumbnailUpload(file);
//       setNewEvent({ ...newEvent, thumbnail: base64 });
//     }
//   };

//   if (id && event) {
//     return (
//       <div className="min-h-screen w-full bg-gray-900 text-white py-4 md:py-0  md:mt-20">
//         <div className="w-full max-w-8xl mx-auto bg-gray-800 rounded-xl shadow-lg flex flex-col md:flex-row overflow-hidden">
//           {/* Mobile Back Button */}
//           <button
//             onClick={() => navigate("/event")}
//             className="md:hidden flex items-center gap-2 p-4 text-blue-400"
//           >
//             <FaArrowLeft /> Back to Events
//           </button>

//           {/* Left Side: Image */}
//           <div className="w-full md:w-1/2 h-64 md:h-auto">
//             <img
//               src={event.thumbnail || "https://via.placeholder.com/600"}
//               alt={event.title}
//               className="w-full h-full object-cover"
//             />
//           </div>

//           {/* Right Side: Content */}
//           <div className="w-full md:w-1/2 p-4 md:p-6 flex flex-col">
//             <h2 className="text-3xl md:text-5xl font-bold text-blue-400">
//               {event.title}
//             </h2>
//             <p className="text-gray-300 mt-2 md:mt-4 text-base md:text-xl">
//               {event.description}
//             </p>
//             <p className="text-gray-400 mt-2 text-sm md:text-lg">
//               📅 {event.date}
//             </p>

//             <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-start gap-3 md:gap-6 mt-6 md:mt-8">
//               <a
//                 href={event.registrationLink}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="bg-blue-500 text-white px-4 py-3 md:px-8 md:py-4 rounded-lg shadow-md hover:bg-blue-600 transition-all text-center text-sm md:text-lg"
//               >
//                 Register Now
//               </a>

//               <button
//                 onClick={handleShare}
//                 className="flex items-center justify-center gap-2 md:gap-3 bg-gray-700 px-4 py-3 md:px-6 md:py-4 rounded-lg hover:bg-gray-600 transition-all text-sm md:text-lg"
//               >
//                 <FaShareAlt className="text-white text-lg md:text-2xl" />
//                 <span>Share</span>
//               </button>
//             </div>

//             {/* Desktop Back Button */}
//             <button
//               onClick={() => navigate("/event")}
//               className="hidden md:block mt-8 w-full bg-gray-700 text-white px-6 py-4 rounded-lg hover:bg-gray-600 transition-all text-lg"
//             >
//               Back to Events
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="p-4 md:p-6 mt-16 md:mt-[100px]">
//       <h2 className="text-2xl font-bold text-center mb-6">Upcoming Events</h2>
      
//       {isAdmin && (
//         <div className="mb-6 border border-gray-600 p-4 rounded-lg shadow-md bg-gray-800">
//           <h3 className="text-xl font-semibold mb-3 text-white">Add New Event (Admin Only)</h3>
//           <input 
//             type="text" 
//             placeholder="Event Title" 
//             className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
//             value={newEvent.title} 
//             onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} 
//           />
//           <input 
//             type="text" 
//             placeholder="Description" 
//             className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
//             value={newEvent.description} 
//             onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })} 
//           />
//           <input 
//             type="date" 
//             className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
//             value={newEvent.date} 
//             onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })} 
//           />
//           <input 
//             type="file" 
//             accept="image/*" 
//             className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
//             onChange={handleFileChange} 
//           />
//           <input 
//             type="text" 
//             placeholder="Registration Link" 
//             className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
//             value={newEvent.registrationLink} 
//             onChange={(e) => setNewEvent({ ...newEvent, registrationLink: e.target.value })} 
//           />
//           <button 
//             onClick={handleAddEvent} 
//             className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 w-full"
//           >
//             Add Event
//           </button>
//         </div>
//       )}
      
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
//         {events.map((event) => (
//           <motion.div
//             key={event.id}
//             whileHover={{ scale: 1.03 }}
//             whileTap={{ scale: 0.98 }}
//             className="backdrop-blur-lg bg-white/10 shadow-xl rounded-lg overflow-hidden cursor-pointer border border-white/20 transition-all"
//             onClick={() => navigate(`/event/${event.id}`)}
//           >
//             <div className="w-full h-48 overflow-hidden">
//               <img
//                 src={event.thumbnail || "https://via.placeholder.com/300"}
//                 alt={event.title}
//                 className="w-full h-full object-contain"
//               />
//             </div>
//             <div className="p-4">
//               <h3 className="text-lg font-semibold text-white line-clamp-1">{event.title}</h3>
//               <p className="text-sm text-gray-200">{event.date}</p>
//             </div>
//           </motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default EventComponent;



import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db, auth } from "./firebase";
import { collection, doc, getDocs, getDoc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import { FaShareAlt, FaArrowLeft, FaEdit, FaTrash, FaTimes, FaCheck } from "react-icons/fa";

const EventComponent = () => {
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    date: "",
    thumbnail: "",
    registrationLink: "",
  });
  const [editingEvent, setEditingEvent] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsAdmin(user && user.email === "snaped4338@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events");
      const eventSnapshot = await getDocs(eventsCollection);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventList);
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    if (id) {
      const fetchEventDetails = async () => {
        const docRef = doc(db, "events", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setEvent({ id: docSnap.id, ...docSnap.data() });
        }
      };

      fetchEventDetails();
    }
  }, [id]);

  const handleRegisterNow = (registrationLink) => {
    if (!registrationLink) {
      alert("No registration link available for this event");
      return;
    }

    // Ensure the link has proper protocol
    let formattedLink = registrationLink;
    if (!/^https?:\/\//i.test(registrationLink)) {
      formattedLink = `https://${registrationLink}`;
    }

    window.open(formattedLink, '_blank', 'noopener,noreferrer');
  };

  const handleShare = async () => {
    const eventURL = `${window.location.origin}/event/${id}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: event.title,
          text: `Check out this event: ${event.title}`,
          url: eventURL,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      navigator.clipboard.writeText(eventURL);
      alert("Link copied to clipboard!");
    }
  };

  const handleThumbnailUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleAddEvent = async () => {
    if (!newEvent.title || !newEvent.date) {
      alert("Title and Date are required!");
      return;
    }

    try {
      await addDoc(collection(db, "events"), newEvent);
      alert("Event added successfully!");
      setNewEvent({
        title: "",
        description: "",
        date: "",
        thumbnail: "",
        registrationLink: "",
      });
      // Refresh events list
      const eventsCollection = collection(db, "events");
      const eventSnapshot = await getDocs(eventsCollection);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventList);
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteDoc(doc(db, "events", eventId));
        alert("Event deleted successfully!");
        if (id) {
          navigate("/event");
        } else {
          // Refresh events list
          const eventsCollection = collection(db, "events");
          const eventSnapshot = await getDocs(eventsCollection);
          const eventList = eventSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvents(eventList);
        }
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  const handleEditEvent = async () => {
    if (!editingEvent.title || !editingEvent.date) {
      alert("Title and Date are required!");
      return;
    }

    try {
      await updateDoc(doc(db, "events", editingEvent.id), editingEvent);
      alert("Event updated successfully!");
      setEditingEvent(null);
      // Refresh events list
      const eventsCollection = collection(db, "events");
      const eventSnapshot = await getDocs(eventsCollection);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventList);
      // If we're on the event detail page, refresh that too
      if (id) {
        const docRef = doc(db, "events", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setEvent({ id: docSnap.id, ...docSnap.data() });
        }
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const handleFileChange = async (e, isEditing = false) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await handleThumbnailUpload(file);
      if (isEditing) {
        setEditingEvent({ ...editingEvent, thumbnail: base64 });
      } else {
        setNewEvent({ ...newEvent, thumbnail: base64 });
      }
    }
  };

  if (id && event) {
    return (
      <div className="min-h-screen w-full bg-gray-900 text-white py-4 md:py-0 md:mt-20">
        <div className="w-full max-w-8xl mx-auto bg-gray-800 rounded-xl shadow-lg flex flex-col md:flex-row overflow-hidden">
          {/* Mobile Back Button */}
          <button
            onClick={() => navigate("/event")}
            className="md:hidden flex items-center gap-2 p-4 text-blue-400"
          >
            <FaArrowLeft /> Back to Events
          </button>

          {/* Left Side: Image */}
          <div className="w-full md:w-1/2 h-64 md:h-auto flex items-center ">
            <img
              src={event.thumbnail || "https://via.placeholder.com/600"}
              alt={event.title}
              className="w-full h-full md:h-auto object-cover md:object-contain"
            />
          </div>

          {/* Right Side: Content */}
          <div className="w-full md:w-1/2 p-4 md:p-6 flex flex-col">
            {isAdmin && !editingEvent && (
              <div className="flex justify-end gap-4 mb-4">
                <button
                  onClick={() => setEditingEvent({ ...event })}
                  className="text-blue-400 hover:text-blue-300 text-xl"
                  title="Edit Event"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => handleDeleteEvent(event.id)}
                  className="text-red-400 hover:text-red-300 text-xl"
                  title="Delete Event"
                >
                  <FaTrash />
                </button>
              </div>
            )}

            {editingEvent ? (
              <div className="space-y-4">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-400 mb-4">
                  Edit Event
                </h2>
                <input
                  type="text"
                  placeholder="Event Title"
                  className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full"
                  value={editingEvent.title}
                  onChange={(e) => setEditingEvent({ ...editingEvent, title: e.target.value })}
                />
                <textarea
                  placeholder="Description"
                  className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full"
                  value={editingEvent.description}
                  onChange={(e) => setEditingEvent({ ...editingEvent, description: e.target.value })}
                  rows="3"
                />
                <input
                  type="date"
                  className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full"
                  value={editingEvent.date}
                  onChange={(e) => setEditingEvent({ ...editingEvent, date: e.target.value })}
                />
                <input
                  type="file"
                  accept="image/*"
                  className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full"
                  onChange={(e) => handleFileChange(e, true)}
                />
                <input
                  type="text"
                  placeholder="Registration Link"
                  className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full"
                  value={editingEvent.registrationLink}
                  onChange={(e) => setEditingEvent({ ...editingEvent, registrationLink: e.target.value })}
                />
                <div className="flex gap-4">
                  <button
                    onClick={() => setEditingEvent(null)}
                    className="flex items-center gap-2 bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition-all"
                  >
                    <FaTimes /> Cancel
                  </button>
                  <button
                    onClick={handleEditEvent}
                    className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-all"
                  >
                    <FaCheck /> Save Changes
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h2 className="text-3xl md:text-5xl font-bold text-blue-400">
                  {event.title}
                </h2>
                <p className="text-gray-300 mt-2 md:mt-4 text-base md:text-xl">
                  {event.description}
                </p>
                <p className="text-gray-400 mt-2 text-sm md:text-lg">
                  📅 {event.date}
                </p>

                <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-start gap-3 md:gap-6 mt-6 md:mt-8">
                  <button
                    onClick={() => handleRegisterNow(event.registrationLink)}
                    className="bg-blue-500 text-white px-4 py-3 md:px-8 md:py-4 rounded-lg shadow-md hover:bg-blue-600 transition-all text-center text-sm md:text-lg"
                  >
                    Register Now
                  </button>

                  <button
                    onClick={handleShare}
                    className="flex items-center justify-center gap-2 md:gap-3 bg-gray-700 px-4 py-3 md:px-6 md:py-4 rounded-lg hover:bg-gray-600 transition-all text-sm md:text-lg"
                  >
                    <FaShareAlt className="text-white text-lg md:text-2xl" />
                    <span>Share</span>
                  </button>
                </div>
              </>
            )}

            {/* Desktop Back Button */}
            <button
              onClick={() => navigate("/event")}
              className="hidden md:block mt-8 w-full bg-gray-700 text-white px-6 py-4 rounded-lg hover:bg-gray-600 transition-all text-lg"
            >
              Back to Events
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 mt-16 md:mt-[100px]">
      <h2 className="text-2xl font-bold text-center mb-6">Upcoming Events</h2>
      
      {isAdmin && (
        <div className="mb-6 border border-gray-600 p-4 rounded-lg shadow-md bg-gray-800">
          <h3 className="text-xl font-semibold mb-3 text-white">Add New Event (Admin Only)</h3>
          <input 
            type="text" 
            placeholder="Event Title" 
            className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
            value={newEvent.title} 
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} 
          />
          <input 
            type="text" 
            placeholder="Description" 
            className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
            value={newEvent.description} 
            onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })} 
          />
          <input 
            type="date" 
            className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
            value={newEvent.date} 
            onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })} 
          />
          <input 
            type="file" 
            accept="image/*" 
            className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
            onChange={handleFileChange} 
          />
          <input 
            type="text" 
            placeholder="Registration Link" 
            className="border border-gray-600 bg-gray-700 text-white p-2 rounded w-full mb-2" 
            value={newEvent.registrationLink} 
            onChange={(e) => setNewEvent({ ...newEvent, registrationLink: e.target.value })} 
          />
          <button 
            onClick={handleAddEvent} 
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 w-full"
          >
            Add Event
          </button>
        </div>
      )}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
        {events.map((event) => (
          <motion.div
            key={event.id}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
            className="backdrop-blur-lg bg-white/10 shadow-xl rounded-lg overflow-hidden cursor-pointer border border-white/20 transition-all relative"
            onClick={() => navigate(`/event/${event.id}`)}
          >
            {isAdmin && (
              <div className="absolute top-2 right-2 flex gap-2 z-10">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/event/${event.id}`);
                    setEditingEvent({ ...event });
                  }}
                  className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-all"
                  title="Edit Event"
                >
                  <FaEdit size={14} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteEvent(event.id);
                  }}
                  className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-all"
                  title="Delete Event"
                >
                  <FaTrash size={14} />
                </button>
              </div>
            )}
            <div className="w-full h-48 overflow-hidden bg-black">
              <img
                src={event.thumbnail || "https://via.placeholder.com/300"}
                alt={event.title}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold text-white line-clamp-1">{event.title}</h3>
              <p className="text-sm text-gray-200">{event.date}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default EventComponent;