
import CascadingDropdown from './components/CascadingDropdown';
import React, { useEffect, useState } from 'react';
import EventComponent from './components/Event';
import Socialicon from './components/Socialicon';
import Footer from './components/Footer';
import ResultManager from './components/Result';
import PostDocs from './components/post';
import TutorialCards from './components/post';
import TutorialPage from './components/TutorialPage';
import BackgroundAnimation from './components/BackgroundAnimation';
import BackgroundAnimationTwo from './components/BackgroundAnimationTwo';
import { motion } from "framer-motion";
import Dashboard from './components/User';
import HeroSection from './components/HeroSection';
import FeedbackPage from './components/feedback';

const Home = () => {
    const [textIndex, setTextIndex] = useState(0);
    const texts = [
      "Notes",
      "Important Questions",
      "Important Topics",
      "PYQ's",
      "Video Lecture Playlist"
    ];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 2000); // Change text every 2 seconds
  
      return () => clearInterval(interval);
    }, [texts.length]);
  return (
    <div className="home-container" >
      {/* <BackgroundAnimationTwo/> */}
     
      <motion.h1
        className="hero-heading text-7xl font-bold text-white drop-shadow-3xl sm:text-5xl md:text-6xl"
        initial={{ scale: 0.8, rotateX: -30 }}
        animate={{ scale: 1, rotateX: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        style={{
          textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
        }}
      >
        Welcome to SnapED codeCampus
      </motion.h1>
    
      <motion.h2
        className="hero-subheading text-5xl font-bold text-white mt-[50px] sm:text-4xl md:text-5xl"
        initial={{ scale: 0.8, rotateX: -30 }}
        animate={{ scale: 1, rotateX: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        style={{
          textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
        }}
      >
        Providing{" "}
        <motion.span
          className="hero-span animated-text text-4xl font-bold text-blue-500 sm:text-3xl md:text-4xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {texts[textIndex]}
        </motion.span>
      </motion.h2>
    <HeroSection/>
    <Socialicon/>
    <Dashboard/>
    <FeedbackPage/>

    <Footer/>
    </div>
  )
}

export default Home;