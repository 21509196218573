// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { doc, setDoc } from "firebase/firestore";
// import React, { useState } from "react";
// import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer
// import "react-toastify/dist/ReactToastify.css"; // Import styles
// import { useNavigate } from "react-router-dom";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import demovideo from "../../assets/demo.mp4";
// import logo from "../../assets/LOGO SnapED.png";
// import { auth, db } from "../../components/firebase";

// function Register() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [fname, setFname] = useState("");
//   const [lname, setLname] = useState("");
//   const [showPassword, setShowPassword] = useState(false);

//   const navigate = useNavigate();

//   const handleRegister = async (e) => {
//     e.preventDefault();
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       const user = userCredential.user;

//       if (user) {
//         await setDoc(doc(db, "Users", user.uid), {
//           email: user.email,
//           firstName: fname,
//           lastName: lname,
//           photo: "",
//         });
//         console.log("User document created in Firestore");
//       }

//       toast.success("User Registered Successfully!", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         theme: "colored",
//       });

//       setEmail("");
//       setPassword("");
//       setFname("");
//       setLname("");

//       setTimeout(() => {
//         navigate("/login");
//       }, 2000);
//     } catch (error) {
//       if (error.code === "auth/email-already-in-use") {
//         toast.error("This email is already in use. Please try another one.", {
//           position: "bottom-center",
//           autoClose: 5000,
//         });
//       } else {
//         toast.error(error.message, {
//           position: "bottom-center",
//           autoClose: 5000,
//         });
//       }
//     }
//   };

//   return (
//     <>
//       <div className="Auth_container">
//         <div className="Auth_screen-section">
//           <div className="Auth_smartphone_two">
//             <video className="Auth_video" autoPlay loop muted>
//               <source src={demovideo} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>

//           <div className="Auth_smartphone">
//             <video className="Auth_video" autoPlay loop muted>
//               <source src={demovideo} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         </div>

//         <div className="Auth_form-section">
//           <form className="Auth_form" onSubmit={handleRegister}>
//             <img className="Auth_login-logo" src={logo} alt="SnapED codeCampus" />
//             <h2 className="Auth_header">Sign Up</h2>

//             <div className="Auth_mb-3">
//               <label>Full Name</label>
//               <input
//                 type="text"
//                 className="Auth_form-control"
//                 placeholder="Full name"
//                 value={fname}
//                 onChange={(e) => setFname(e.target.value)}
//                 required
//               />
//             </div>

//             <div className="Auth_mb-3">
//               <label>College Name/Organization Name</label>
//               <input
//                 type="text"
//                 className="Auth_form-control"
//                 placeholder="College name"
//                 value={lname}
//                 onChange={(e) => setLname(e.target.value)}
//                 required
//               />
//             </div>

//             <div className="Auth_mb-3">
//               <label>Email address</label>
//               <input
//                 type="email"
//                 className="Auth_form-control"
//                 placeholder="Enter email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//             </div>

//             <div className="Auth_mb-3">
//               <label>Password</label>
//               <div className="Auth_password-container">
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   className="Auth_form-control"
//                   placeholder="Enter password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   required
//                 />
//                 {/* <span
//                   onClick={() => setShowPassword(!showPassword)}
//                   className="Auth_password-toggle-icon"
//                 >
//                   {showPassword ? <FaEyeSlash /> : <FaEye />}
//                 </span> */}
//               </div>
//             </div>

//             <div className="Auth_d-grid">
//               <button type="submit" className="Auth_btn-primary">
//                 Sign Up
//               </button>
//             </div>

//             <p className="Auth_forgot-password Auth_text-right">
//               Have an account? <a href="/login">Login</a>
//             </p>
//           </form>
//         </div>
//       </div>

//       <ToastContainer
//         position="top-center"
//         autoClose={5000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="light"
//       />

//       {/* <footer>
//         <p>-- &copy; 2024 SnapED codeCampus. All rights reserved. --</p>
//       </footer> */}
//     </>
//   );
// }

// export default Register;



import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { auth, db } from "../../components/firebase";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fullNameRef = useRef(null);
  const collegeNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);

      // Store additional user data in Firestore
      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          fullName: fullName,
          collegeName: collegeName,
          photo: "",
          emailVerified: false,
        });
      }

      // Show success toast with Gmail button
      toast.success(
        <div>
          <p>Verification email sent to {email}</p>
          <button
            onClick={() => window.open("https://mail.google.com", "_blank")}
            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded text-sm"
          >
            Open Gmail
          </button>
        </div>,
        {
          position: "top-center",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
        }
      );

      // Check email verification status periodically
      const checkVerification = setInterval(async () => {
        await user.reload();
        if (user.emailVerified) {
          clearInterval(checkVerification);
          toast.dismiss();
          toast.success("Email verified successfully!", {
            position: "top-center",
            autoClose: 3000,
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      }, 5000);

      // Reset form
      setEmail("");
      setPassword("");
      setFullName("");
      setCollegeName("");

    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/email-already-in-use") {
        toast.error("This email is already in use. Please try another one.", {
          position: "bottom-center",
          autoClose: 5000,
        });
      } else {
        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle input focus
  const handleFocus = (inputRef) => {
    const label = inputRef.current.previousSibling;
    label.classList.add("-translate-y-[1.15rem]", "scale-[0.8]", "text-primary");
  };

  // Function to handle input blur
  const handleBlur = (inputRef) => {
    const label = inputRef.current.previousSibling;
    if (!inputRef.current.value) {
      label.classList.remove("-translate-y-[1.15rem]", "scale-[0.8]", "text-primary");
    }
  };

  return (
    <section className="h-screen bg-gray-900">
      <div className="h-full">
        <div className="flex h-full flex-wrap items-center justify-center lg:justify-between">
          {/* Left column container with background */}
          <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
            <img
              src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="w-full"
              alt="Sample image"
            />
          </div>

          {/* Right column container */}
          <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
            <div className=" bg-[#003161] rounded-lg p-8 shadow-lg">
              <h2 className="text-2xl font-bold text-white mb-6 text-center">Create Account</h2>
              <form onSubmit={handleRegister}>
                {/* Full Name input */}
                <div className="relative mb-6">
                  <label
                    htmlFor="fullName"
                    className={`absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-300 transition-all duration-200 ease-out ${fullName ? "-translate-y-[1.15rem] scale-[0.8] text-primary" : ""
                      }`}
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    ref={fullNameRef}
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 border-b border-gray-300 focus:border-primary text-white"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    onFocus={() => handleFocus(fullNameRef)}
                    onBlur={() => handleBlur(fullNameRef)}
                    required
                  />
                </div>

                {/* College/Organization Name input */}
                <div className="relative mb-6">
                  <label
                    htmlFor="collegeName"
                    className={`absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-300 transition-all duration-200 ease-out ${collegeName ? "-translate-y-[1.15rem] scale-[0.8] text-primary" : ""
                      }`}
                  >
                    College/Organization Name
                  </label>
                  <input
                    type="text"
                    id="collegeName"
                    ref={collegeNameRef}
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 border-b border-gray-300 focus:border-primary text-white"
                    value={collegeName}
                    onChange={(e) => setCollegeName(e.target.value)}
                    onFocus={() => handleFocus(collegeNameRef)}
                    onBlur={() => handleBlur(collegeNameRef)}
                    required
                  />
                </div>

                {/* Email input */}
                <div className="relative mb-6">
                  <label
                    htmlFor="email"
                    className={`absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-300 transition-all duration-200 ease-out ${email ? "-translate-y-[1.15rem] scale-[0.8] text-primary" : ""
                      }`}
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    ref={emailRef}
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 border-b border-gray-300 focus:border-primary text-white"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => handleFocus(emailRef)}
                    onBlur={() => handleBlur(emailRef)}
                    required
                  />
                </div>

                {/* Password input */}


                <div className="relative mb-6">
                  <label
                    htmlFor="password"
                    className={`absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-300 transition-all duration-200 ease-out ${email ? "-translate-y-[1.15rem] scale-[0.8] text-primary" : ""
                      }`}
                  >
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    ref={passwordRef}
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 border-b border-gray-300 focus:border-primary text-white"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => handleFocus(passwordRef)}
                    onBlur={() => handleBlur(passwordRef)}
                    required
                    minLength="6"
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-200"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>

                <div className="mb-6 flex items-center justify-between">
                  {/* Terms and conditions checkbox */}
                  <div className="mb-[0.125rem] block min-h-[1.5rem] ps-[1.5rem]">
                    <input
                      className="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-gray-300 outline-none checked:border-primary checked:bg-primary checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer focus:shadow-none focus:transition-[border-color_0.2s]"
                      type="checkbox"
                      id="termsCheck"
                      required
                    />
                    <label
                      className="inline-block ps-[0.15rem] hover:cursor-pointer text-gray-300"
                      htmlFor="termsCheck"
                    >
                      I agree to the{' '}
                      <Link to="/terms & condition" className="text-primary hover:underline">
                        terms and conditions
                      </Link>
                    </label>
                  </div>
                </div>

                {/* Register button */}
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full rounded bg-gradient-to-r from-blue-500 to-indigo-600 px-7 pb-2 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gradient-to-r hover:from-blue-600 hover:to-indigo-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gradient-to-r focus:from-blue-600 focus:to-indigo-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gradient-to-r active:from-blue-700 active:to-indigo-800 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </span>
                    ) : (
                      "Register"
                    )}
                  </button>

                  {/* Login link */}
                  <p className="mb-0 mt-4 pt-1 text-sm font-semibold text-gray-300">
                    Have an account?{' '}
                    <Link
                      to="/login"
                      className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700"
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
    </section>
  );
}

export default Register;