
import { useEffect, useRef } from 'react'

export default function BackgroundAnimation() {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    let animationFrameId
    
    const setCanvasSize = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }
    setCanvasSize()
    window.addEventListener('resize', setCanvasSize)

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width
        this.y = Math.random() * canvas.height
        this.size = Math.random() * 2 + 1
        this.speedX = Math.random() * 2 - 1
        this.speedY = Math.random() * 2 - 1
        this.color = `rgba(59, 130, 246, ${Math.random() * 0.5})`
      }

      update() {
        this.x += this.speedX
        this.y += this.speedY

        if (this.x > canvas.width) this.x = 0
        if (this.x < 0) this.x = canvas.width
        if (this.y > canvas.height) this.y = 0
        if (this.y < 0) this.y = canvas.height
      }

      draw() {
        ctx.fillStyle = this.color
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2)
        ctx.fill()
      }
    }

    const particles = Array.from({ length: 50 }, () => new Particle())

    const animate = () => {
      ctx.fillStyle = 'rgba(0, 18, 51, 0.1)'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      particles.forEach(particle => {
        particle.update()
        particle.draw()
      })

      animationFrameId = requestAnimationFrame(animate)
    }

    animate()

    return () => {
      window.removeEventListener('resize', setCanvasSize)
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return <canvas ref={canvasRef} className="fixed inset-0 pointer-events-none" />
}


// import { useEffect, useRef } from 'react'

// export default function BackgroundAnimation() {
//   const canvasRef = useRef(null)

//   useEffect(() => {
//     const canvas = canvasRef.current
//     const ctx = canvas.getContext('2d')
//     let animationFrameId

//     const setCanvasSize = () => {
//       canvas.width = window.innerWidth
//       canvas.height = window.innerHeight
//     }
//     setCanvasSize()
//     window.addEventListener('resize', setCanvasSize)

//     class Particle {
//       constructor() {
//         this.x = Math.random() * canvas.width
//         this.y = Math.random() * canvas.height
//         this.size = Math.random() * 2 + 1
//         this.speedX = Math.random() * 2 - 1
//         this.speedY = Math.random() * 2 - 1
//         this.color = `rgba(59, 130, 246, ${Math.random() * 0.5})`
//       }

//       update() {
//         this.x += this.speedX
//         this.y += this.speedY

//         if (this.x > canvas.width) this.x = 0
//         if (this.x < 0) this.x = canvas.width
//         if (this.y > canvas.height) this.y = 0
//         if (this.y < 0) this.y = canvas.height
//       }

//       draw() {
//         ctx.fillStyle = this.color
//         ctx.beginPath()
//         ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2)
//         ctx.fill()
//       }
//     }

//     const particles = Array.from({ length: 50 }, () => new Particle())

//     const animate = () => {
//       ctx.fillStyle = 'rgba(0, 18, 51, 0.1)'
//       ctx.fillRect(0, 0, canvas.width, canvas.height)

//       particles.forEach(particle => {
//         particle.update()
//         particle.draw()
//       })

//       animationFrameId = requestAnimationFrame(animate)
//     }

//     animate()

//     return () => {
//       window.removeEventListener('resize', setCanvasSize)
//       cancelAnimationFrame(animationFrameId)
//     }
//   }, [])

//   return (
//     <canvas
//       ref={canvasRef}
//       className="absolute top-0 left-0 w-full h-full -z-10 pointer-events-none"
//     />
//   )
// }

