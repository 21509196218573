// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { motion } from 'framer-motion';
// import styled from '@emotion/styled';
// import banner_img from "../assets/banner_1.png";

// const HeroContainer = styled.div`
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
//   padding: 80px 1rem 2rem;
//   position: relative;
//   overflow: hidden;

//   @media (min-width: 768px) {
//     padding: 100px 2rem 4rem;
//   }
// `;



// const BackgroundAnimation = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: var(--primary-glow);
//   filter: blur(120px);
//   opacity: 0.4;
//   z-index: 0;
//   pointer-events: none;
// `;


// const ControlsContainer = styled(motion.div)`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;
//   max-width: 500px;
//   padding: 1.5rem;
//   background: rgba(10, 25, 47, 0.7);
//   backdrop-filter: blur(10px);
//   border-radius: 1rem;
//   border: 1px solid rgba(255, 255, 255, 0.1);

//   @media (min-width: 768px) {
//       margin-top: 150px;
//   }
// `;

// const Select = styled.select`
//   width: 100%;
//   padding: 0.8rem;
//   background: rgba(255, 255, 255, 0.05);
//   border: 1px solid rgba(255, 255, 255, 0.1);
//   border-radius: 0.75rem;
//   color: white;
//   font-size: 0.9rem;
//   cursor: pointer;

//   @media (min-width: 768px) {
//     padding: 1rem;
//     font-size: 1rem;
//   }

//   &:hover, &:focus {
//     border-color: #00ffff;
//     background: rgba(255, 255, 255, 0.1);
//   }

//   option {
//     background: #0a192f;
//     color: white;
//     padding: 10px;
//   }
// `;

// const ExploreButton = styled(motion.button)`
//   padding: 0.8rem;
//   font-size: 0.9rem;
//   background: linear-gradient(45deg, #00ffff, #00ff88);
//   border: none;
//   border-radius: 0.75rem;
//   color: #000;
//   font-weight: 600;
//   cursor: pointer;
//   width: 100%;
//   text-transform: uppercase;
//   letter-spacing: 0.5px;

//   @media (min-width: 768px) {
//     padding: 1rem;
//     font-size: 1rem;
//   }

//   &:hover {
//     transform: translateY(-2px);
//     box-shadow: 0 0 20px rgba(0, 255, 255, 0.4);
//   }
// `;

// const HeroSection = () => {
//     const navigate = useNavigate();
//     const [course, setCourse] = useState("");
//     const [branch, setBranch] = useState("");
//     const [semester, setSemester] = useState("");
  
    
//     const [isCourseOpen, setIsCourseOpen] = useState(false);
//     const [isBranchOpen, setIsBranchOpen] = useState(false);
//     const [isSemesterOpen, setIsSemesterOpen] = useState(false);
  
//     const handleExploreClick = () => {
//         if (course && branch && semester) {
//           navigate(`/${course}/${branch}/${semester}`);
//         } else {
//           alert("Please select all fields to proceed!");
//         }
//       };
    
//       const handleCourseChange = (event) => {
//         setCourse(event.target.value);
//       };
    
//       const handleBranchChange = (event) => {
//         setBranch(event.target.value);
//       };
    
//       const handleSemesterChange = (event) => {
//         setSemester(event.target.value);
//       };
//   return (
//     <div className="container">
//       <section id="right">
//         <img src={banner_img} alt="banner_image" />
//       </section>
//       <section id="left">
//             <HeroContainer>
//       <BackgroundAnimation
//         animate={{
//           scale: [1, 1.2, 1],
//           rotate: [0, 180, 360],
//         }}
//         transition={{
//           duration: 30,
//           repeat: Infinity,
//           ease: "linear"
//         }}
//       />
        
//         <ControlsContainer
//           initial={{ scale: 0.9, opacity: 0 }}
//           animate={{ scale: 1, opacity: 1 }}
//           transition={{ duration: 0.5, delay: 0.4 }}
//         >
//           <Select
//                          value={course}
//                          onChange={handleCourseChange}
//                          onClick={() => setIsCourseOpen(!isCourseOpen)} 
//                          animate={{ scaleY: isCourseOpen ? 1.2 : 1 }} 
//           >
//             <option value="Select Course">Select Course</option>
//             <option value="btech">BTech</option>
//           </Select>
          
//           <Select 
//                          value={branch}
//                          onChange={handleBranchChange}
//                          onClick={() => setIsBranchOpen(!isBranchOpen)} 
//           >
//           <option value="">Select Branch</option>
//               {course === "btech" && (
//                 <>
//                   <option value="cse">CSE</option>
//                   <option value="aiml">AIML</option>
//                   <option value="aids">AIDS</option>
//                   <option value="it">IT</option>
//                 </>
//               )}
//           </Select>
          
//           <Select 
//                          value={semester}
//                          onChange={handleSemesterChange}
//                          onClick={() => setIsSemesterOpen(!isSemesterOpen)}
//           >
//               <option value="">Select Semester</option>
//               <option value="1st">1st Semester</option>
//               <option value="2nd">2nd Semester</option>
//               <option value="3rd">3rd Semester</option>
//               <option value="4th">4th Semester</option>
//               <option value="5th">5th Semester</option>
//           </Select>
          
//           <ExploreButton
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           onClick={handleExploreClick}
//         >
//           📚 Notes Mode: Activated! 🤓
//         </ExploreButton>

//         </ControlsContainer>
//     </HeroContainer>
//     </section>
//     </div>

//   );
// };

// export default HeroSection;





import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import banner_img from "../assets/banner_1.png";

const HeroContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 1rem 2rem;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 100px 2rem 4rem;
  }
`;

const BackgroundAnimation = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-glow);
  filter: blur(120px);
  opacity: 0.4;
  z-index: 0;
  pointer-events: none;
`;

const ControlsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
  padding: 1.5rem;
  background: rgba(10, 25, 47, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (min-width: 768px) {
    margin-top: 150px;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;

  @media (min-width: 768px) {
    padding: 1rem;
    font-size: 1rem;
  }

  &:hover, &:focus {
    border-color: #00ffff;
    background: rgba(255, 255, 255, 0.1);
  }

  option {
    background: #0a192f;
    color: white;
    padding: 10px;
  }
`;

const ExploreButton = styled(motion.button)`
  padding: 0.8rem;
  font-size: 0.9rem;
  background: linear-gradient(45deg, #00ffff, #00ff88);
  border: none;
  border-radius: 0.75rem;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @media (min-width: 768px) {
    padding: 1rem;
    font-size: 1rem;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.4);
  }
`;

const AdContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
  min-height: 100px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 1rem;
`;

const AdNotification = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-top: 0.5rem;
`;

const HeroSection = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [semester, setSemester] = useState("");
  const [isCourseOpen, setIsCourseOpen] = useState(false);
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [isSemesterOpen, setIsSemesterOpen] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2887238820446530';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleExploreClick = () => {
    if (course && branch && semester) {
      setClickCount(prev => prev + 1);
      
      // Show ad on every 3rd click
      if (clickCount % 3 === 0) {
        setShowAd(true);
        // Hide ad after 30 seconds
        setTimeout(() => {
          setShowAd(false);
        }, 30000);
      }
      
      // Immediate navigation
      navigate(`/${course}/${branch}/${semester}`);
    } else {
      alert("Please select all fields to proceed!");
    }
  };

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSemester(event.target.value);
  };

  const renderAd = () => {
    if (!showAd) return null;

    return (
      <>
        <AdContainer>
          <ins 
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2887238820446530"
            data-ad-slot="1502817186"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script dangerouslySetInnerHTML={{ __html: '(adsbygoogle = window.adsbygoogle || []).push({});' }} />
        </AdContainer>
        <AdNotification>
          Thank you for supporting us by viewing this ad
        </AdNotification>
      </>
    );
  };

  return (
    <div className="container">
      <section id="right">
        <img src={banner_img} alt="banner_image" />
      </section>
      <section id="left">
        <HeroContainer>
          <BackgroundAnimation
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 180, 360],
            }}
            transition={{
              duration: 30,
              repeat: Infinity,
              ease: "linear"
            }}
          />
          
          <ControlsContainer
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Select
              value={course}
              onChange={handleCourseChange}
              onClick={() => setIsCourseOpen(!isCourseOpen)} 
              animate={{ scaleY: isCourseOpen ? 1.2 : 1 }} 
            >
              <option value="">Select Course</option>
              <option value="btech">BTech</option>
            </Select>
            
            <Select 
              value={branch}
              onChange={handleBranchChange}
              onClick={() => setIsBranchOpen(!isBranchOpen)} 
            >
              <option value="">Select Branch</option>
              {course === "btech" && (
                <>
                  <option value="cse">CSE</option>
                  <option value="aiml">AIML</option>
                  <option value="aids">AIDS</option>
                  <option value="it">IT</option>
                </>
              )}
            </Select>
            
            <Select 
              value={semester}
              onChange={handleSemesterChange}
              onClick={() => setIsSemesterOpen(!isSemesterOpen)}
            >
              <option value="">Select Semester</option>
              <option value="1st">1st Semester</option>
              <option value="2nd">2nd Semester</option>
              <option value="3rd">3rd Semester</option>
              <option value="4th">4th Semester</option>
              <option value="5th">5th Semester</option>
            </Select>
            
            <ExploreButton
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleExploreClick}
            >
              📚 Notes Mode: Activated! 🤓
            </ExploreButton>

            {renderAd()}
          </ControlsContainer>
        </HeroContainer>
      </section>
    </div>
  );
};

export default HeroSection;