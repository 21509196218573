import React, { useState, useEffect } from 'react';
import { db, collection, getDocs } from './firebase';
import { motion } from 'framer-motion';
import BackgroundAnimation from './BackgroundAnimation';

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        
        const userCollection = collection(db, 'Users');
        
        
        const userSnapshot = await getDocs(userCollection);
        
       
        setTotalUsers(userSnapshot.size); 
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    
    fetchTotalUsers();
  }, []);

  return (
    <>
    <BackgroundAnimation/>
    <div className="dashboard backdrop-blur-xl bg-white/10">
        
        <motion.div
          className="header"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h1
            initial={{ rotateY: 45 }}
            animate={{ rotateY: 0 }}
            transition={{ duration: 1 }}
          >
            Study Smarter, Code Better – Join the Most Trusted Community! ❤️
          </motion.h1>
          
          <motion.p
            className="stat"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.5 }}
          >
            {totalUsers}+ 
          </motion.p>
          
          <motion.p
            className="stat"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            Happy Learners 🤓
          </motion.p>
        </motion.div>
      </div>
    </>

  );
};

export default Dashboard;
