// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   FaBars,
//   FaTimes,
//   FaHome,
//   FaInfoCircle,
//   FaPhoneAlt,
//   FaUser,
//   FaBullhorn,
//   FaHandsHelping,
//   FaBook,
// } from "react-icons/fa";

// const ContributeButton = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [hoveredItem, setHoveredItem] = useState(null);

//   const toggleMenu = () => {
//     setMenuOpen((prev) => !prev);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   return (
//     <div
//       style={{
//         position: "fixed",
//         bottom: "20px",
//         right: "20px",
//         zIndex: 9999,
//         display: window.innerWidth > 1024 ? "none" : "block", // Hide on larger screens
//       }}
//     >
//       {/* Floating Options */}
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "10px",
//           marginBottom: menuOpen ? "10px" : "0px",
//           opacity: menuOpen ? 1 : 0,
//           transform: menuOpen ? "scale(1)" : "scale(0.8)",
//           transition: "all 0.3s ease",
//           pointerEvents: menuOpen ? "auto" : "none",
//         }}
//       >
//         {menuItems.map((item, index) => (
//           <div
//             key={index}
//             style={{
//               position: "relative",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//             onMouseEnter={() => setHoveredItem(index)}
//             onMouseLeave={() => setHoveredItem(null)}
//           >
//             {/* Tooltip */}
//             {hoveredItem === index && (
//               <div
//                 style={{
//                   position: "absolute",
//                   bottom: "60px",
//                   backgroundColor: "rgba(0, 0, 0, 0.8)",
//                   color: "white",
//                   padding: "5px 10px",
//                   borderRadius: "5px",
//                   fontSize: "12px",
//                   whiteSpace: "nowrap",
//                   zIndex: 10,
//                 }}
//               >
//                 {item.label}
//               </div>
//             )}
//             {item.isExternal ? (
//               <button
//                 onClick={() => {
//                   item.action();
//                   closeMenu();
//                 }}
//                 style={{
//                   ...menuButtonStyle,
//                   transform: hoveredItem === index ? "scale(1.2)" : "scale(1)",
//                   transition: "transform 0.2s ease, background-color 0.3s ease",
//                 }}
//                 aria-label={item.label}
//               >
//                 {item.icon}
//               </button>
//             ) : (
//               <Link
//                 to={item.link}
//                 style={{
//                   textDecoration: "none",
//                 }}
//                 onClick={closeMenu}
//               >
//                 <button
//                   style={{
//                     ...menuButtonStyle,
//                     transform: hoveredItem === index ? "scale(1.2)" : "scale(1)",
//                     transition: "transform 0.2s ease, background-color 0.3s ease",
//                   }}
//                   aria-label={item.label}
//                 >
//                   {item.icon}
//                 </button>
//               </Link>
//             )}
//           </div>
//         ))}
//       </div>

//       {/* Main Button */}
//       <button
//         onClick={toggleMenu}
//         style={{
//           ...mainButtonStyle,
//           ...(menuOpen ? hoverEffectStyle : {}),
//         }}
//         aria-label="Menu"
//       >
//         {menuOpen ? <FaTimes size={32} /> : <FaBars size={32} />}
//       </button>
//     </div>
//   );
// };

// const menuItems = [
//   { label: "Home", link: "/", icon: <FaHome size={32} /> },
//   { label: "About", link: "/about", icon: <FaInfoCircle size={32} /> },
//   { label: "Contact", link: "/contactus", icon: <FaPhoneAlt size={32} /> },
//   { label: "Profile", link: "/profile", icon: <FaUser size={32} /> },
//   { label: "Event", link: "/event", icon: <FaBullhorn size={32} /> },
//   { label: "TechLibrary", link: "/tech-library", icon: <FaBook size={32} /> },
//   {
//     label: "Contribute",
//     isExternal: true,
//     action: () => window.open("https://forms.gle/ufk6ibsZdRkhxuTc8", "_blank"),
//     icon: <FaHandsHelping size={32} />,
//   },
// ];

// const mainButtonStyle = {
//   width: "70px", // Slightly larger for main button
//   height: "70px",
//   borderRadius: "50%",
//   backgroundColor: "#4CAF50",
//   color: "white",
//   border: "none",
//   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//   cursor: "pointer",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   transition: "background-color 0.3s ease, transform 0.2s ease",
//   marginBottom: "40px",
// };

// const menuButtonStyle = {
//   width: "60px", // Button size matches icon
//   height: "60px",
//   borderRadius: "50%",
//   color: "white",
//   border: "none",
//   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//   cursor: "pointer",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "#03346e",
//   padding: "0", // No extra padding
// };

// const hoverEffectStyle = {
//   backgroundColor: "#FF4D4D",
//   transform: "rotate(90deg)",
// };

// export default ContributeButton;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaInfoCircle,
  FaPhoneAlt,
  FaUser,
  FaBullhorn,
  FaHandsHelping,
  FaBook,
} from "react-icons/fa";

const ContributeButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showAd, setShowAd] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    // Load Google AdSense script
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2887238820446530';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleContributeClick = () => {
    setClickCount(prev => prev + 1);
    
    // Show ad on every 3rd click
    if (clickCount % 3 === 0) {
      setShowAd(true);
      // Hide ad after 30 seconds
      setTimeout(() => {
        setShowAd(false);
      }, 30000);
    }
    
    // Open contribution form
    window.open("https://forms.gle/ufk6ibsZdRkhxuTc8", "_blank");
  };

  const menuItems = [
    { label: "Home", link: "/", icon: <FaHome size={32} /> },
    { label: "About", link: "/about", icon: <FaInfoCircle size={32} /> },
    { label: "Contact", link: "/contactus", icon: <FaPhoneAlt size={32} /> },
    { label: "Profile", link: "/profile", icon: <FaUser size={32} /> },
    { label: "Event", link: "/event", icon: <FaBullhorn size={32} /> },
    { label: "TechLibrary", link: "/blog", icon: <FaBook size={32} /> },
    {
      label: "Contribute",
      isExternal: true,
      action: handleContributeClick,
      icon: <FaHandsHelping size={32} />,
    },
  ];

  const renderAd = () => {
    if (!showAd) return null;

    return (
      <div style={{
        position: 'fixed',
        bottom: '120px',
        right: '20px',
        width: '300px',
        minHeight: '250px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '8px',
        padding: '10px',
        zIndex: 10000,
        border: '1px solid rgba(255,255,255,0.2)'
      }}>
        <ins 
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-2887238820446530"
          data-ad-slot="1502817186"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script dangerouslySetInnerHTML={{ __html: '(adsbygoogle = window.adsbygoogle || []).push({});' }} />
        <div style={{
          fontSize: '12px',
          color: 'rgba(255,255,255,0.7)',
          textAlign: 'center',
          marginTop: '5px'
        }}>
          Thank you for supporting us
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 9999,
        display: window.innerWidth > 1024 ? "none" : "block",
      }}
    >
      {renderAd()}

      {/* Floating Options */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: menuOpen ? "10px" : "0px",
          opacity: menuOpen ? 1 : 0,
          transform: menuOpen ? "scale(1)" : "scale(0.8)",
          transition: "all 0.3s ease",
          pointerEvents: menuOpen ? "auto" : "none",
        }}
      >
        {menuItems.map((item, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {/* Tooltip */}
            {hoveredItem === index && (
              <div
                style={{
                  position: "absolute",
                  bottom: "60px",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  zIndex: 10,
                }}
              >
                {item.label}
              </div>
            )}
            {item.isExternal ? (
              <button
                onClick={() => {
                  item.action();
                  closeMenu();
                }}
                style={{
                  ...menuButtonStyle,
                  transform: hoveredItem === index ? "scale(1.2)" : "scale(1)",
                  transition: "transform 0.2s ease, background-color 0.3s ease",
                }}
                aria-label={item.label}
              >
                {item.icon}
              </button>
            ) : (
              <Link
                to={item.link}
                style={{
                  textDecoration: "none",
                }}
                onClick={closeMenu}
              >
                <button
                  style={{
                    ...menuButtonStyle,
                    transform: hoveredItem === index ? "scale(1.2)" : "scale(1)",
                    transition: "transform 0.2s ease, background-color 0.3s ease",
                  }}
                  aria-label={item.label}
                >
                  {item.icon}
                </button>
              </Link>
            )}
          </div>
        ))}
      </div>

      {/* Main Button */}
      <button
        onClick={toggleMenu}
        style={{
          ...mainButtonStyle,
          ...(menuOpen ? hoverEffectStyle : {}),
        }}
        aria-label="Menu"
      >
        {menuOpen ? <FaTimes size={32} /> : <FaBars size={32} />}
      </button>
    </div>
  );
};

const mainButtonStyle = {
  width: "70px",
  height: "70px",
  borderRadius: "50%",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  marginBottom: "40px",
};

const menuButtonStyle = {
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  color: "white",
  border: "none",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#03346e",
  padding: "0",
};

const hoverEffectStyle = {
  backgroundColor: "#FF4D4D",
  transform: "rotate(90deg)",
};

export default ContributeButton;