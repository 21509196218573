import { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Target, BookOpen, Flag } from 'lucide-react';
import Footer from './Footer';



const TermsandConditon = () => {
        const cards = [
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Acceptance of Terms",
            description: "By accessing, registering, or using any part of the SnapED CodeCampus website and services (collectively referred to as the Website), you agree to comply with and be bound by these Terms and Conditions (Terms). If you do not agree with these Terms, you must refrain from using the Website. SnapED CodeCampus reserves the right to update or change these Terms at any time, and your continued use of the Website signifies your acceptance of those changes."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: " Changes to Terms",
            description: "SnapED CodeCampus may revise and update these Terms at its sole discretion. The most recent version of the Terms will be posted on the Website, and it is your responsibility to check these Terms periodically. Any changes will be effective immediately upon posting, unless otherwise specified. By continuing to use the Website after such changes, you accept the modified Terms."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: " Use of Services",
            description: "SnapED CodeCampus provides an online platform for users to engage in various educational and community activities, including but not limited to coding tutorials, discussions, resources, and networking opportunities. You may use the Website solely for lawful purposes in accordance with these Terms. You agree not to use the Website for any activity that could harm or disrupt the services provided."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Account Registration and Security",
            description: "To access certain features of the Website, you may need to register for an account. During the registration process, you agree to provide accurate, current, and complete information and to update your details as necessary. You are responsible for maintaining the confidentiality of your account and password, as well as for all activities that occur under your account. You agree to notify SnapED CodeCampus immediately if you suspect any unauthorized access or security breaches related to your account."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Member Content",
            description: "SnapED CodeCampus allows you to post, upload, or share content (Member Content) including comments, posts, images, videos, and other materials. By submitting Member Content, you grant SnapED CodeCampus a non-exclusive, royalty-free, worldwide license to use, display, distribute, and modify your content as necessary for the operation of the Website. You retain ownership of your Member Content but agree that SnapED CodeCampus may use it in connection with providing services to other users, promoting the Website, or fulfilling its obligations under these Terms. You are solely responsible for your Member Content, and you represent and warrant that you have the right to post or share it."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Prohibited Activities",
            description: "You agree not to engage in any of the following activities: Uploading or sharing content that is illegal, defamatory, harassing, abusive, offensive, or otherwise violates the rights of others.| Impersonating another person or entity, or otherwise providing false or misleading information. | Engaging in spamming or other disruptive behavior, including the dissemination of unsolicited advertisements or promotional materials. | Attempting to interfere with, disrupt, or gain unauthorized access to any part of the Website or its infrastructure. | Uploading or distributing viruses, malware, or other harmful code that may damage or compromise the functionality of the Website or other users' devices."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Privacy Policy",
            description: "Your use of the Website is also governed by our Privacy Policy, which outlines how we collect, store, and use your personal data. Please review the Privacy Policy before using the Website to understand your rights regarding your personal information."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Limitation of Liability",
            description: "To the maximum extent permitted by law, SnapED CodeCampus, its affiliates, directors, employees, and agents will not be held liable for any damages arising from your use or inability to use the Website, including but not limited to, direct, indirect, incidental, special, or consequential damages. This limitation of liability applies even if SnapED CodeCampus has been advised of the possibility of such damages."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Indemnification",
            description: "You agree to indemnify and hold harmless SnapED CodeCampus, its affiliates, subsidiaries, officers, directors, employees, and agents from and against any claims, damages, losses, liabilities, and expenses, including reasonable legal and accounting fees, arising out of or in any way connected with: Your use of or access to the Website, including any content you submit or post. | Your violation of these Terms or any applicable law or regulation. | Any dispute or interaction between you and other users of the Website."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Entire Agreement",
            description: "These Terms, together with any additional policies, guidelines, or documents incorporated by reference, constitute the entire agreement between you and SnapED CodeCampus regarding your use of the Website. These Terms supersede any prior or contemporaneous oral or written agreements or communications between you and SnapED CodeCampus."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Notices",
            description: "Any notices required or permitted under these Terms, including modifications or updates to the Terms, will be sent via email or posted on the Website. Notices sent via email will be deemed to have been received on the date the email is transmitted. You agree to maintain a valid email address for receiving such notices."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Governing Law and Jurisdiction",
            description: "These Terms will be governed by and construed in accordance with the laws of India, excluding its rules regarding conflicts of laws. You agree to submit to the personal jurisdiction of the courts located in New Delhi, India for any disputes related to these Terms or your use of the Website. This provision does not prevent you from seeking injunctive or other equitable relief in a court of competent jurisdiction to protect your intellectual property rights."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Arbitration",
            description: "Any dispute, claim, or controversy arising out of or relating to these Terms, or your use of the Website, will be resolved by binding arbitration in New Delhi, India, in accordance with the Arbitration and Conciliation Act, 1996. The arbitration will be conducted in English, and the arbitrator's decision will be final and binding."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "No Waiver",
            description: "The failure of SnapED CodeCampus to enforce any right or provision of these Terms will not be deemed a waiver of that right or provision. Any waiver of these Terms must be in writing and signed by an authorized representative of SnapED CodeCampus. A waiver of any provision will not be deemed to be a waiver of any other provision of these Terms."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Miscellaneous",
            description: "SnapED CodeCampus is not responsible for any delay or failure in the performance of its services due to causes beyond its reasonable control, including but not limited to natural disasters, government actions, or internet service disruptions. | You may not assign or transfer these Terms without the prior written consent of SnapED CodeCampus. Any unauthorized attempt to assign these Terms will be of no effect. SnapED CodeCampus may assign or transfer these Terms without restriction.| SnapED CodeCampus reserves the right to modify, suspend, or discontinue the Website or its services at any time, with or without notice. SnapED CodeCampus will not be liable to you or any third party for any changes to or discontinuation of the Website."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Termination",
            description: "SnapED CodeCampus reserves the right to suspend or terminate your access to the Website if you violate any provision of these Terms. Upon termination, you must cease all use of the Website, and any outstanding obligations or liabilities will remain in effect."
          },
          {
            icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
            title: "Contact Information",
            description: "For any questions or concerns regarding these Terms, please contact SnapED CodeCampus at [ snaped4338@gmail.com ]."
          }
        ]
        
        



    const titleRef = useRef(null)

useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-title')
      }
    })
  })

  if (titleRef.current) {
    observer.observe(titleRef.current)
  }

  return () => observer.disconnect()
}, [])
  return (
    <>    <section className="min-h-[80vh] flex flex-col items-center justify-center text-center px-4 relative mb-[-8rem]">
    <div className="absolute inset-0 bg-gradient-radial from-blue-500/10 via-transparent to-transparent" />
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="backdrop-blur-sm bg-white/5 rounded-2xl p-6 md:p-10 max-w-4xl mx-auto"
    >
      <h1 ref={titleRef} className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight font-orbitron opacity-0">
      Terms and Conditions SnapED <br />codeCampus
      </h1>

    </motion.div>
    <style jsx>{`
      @keyframes titleAnimation {
        0% { opacity: 0; transform: translateY(20px); }
        100% { opacity: 1; transform: translateY(0); }
      }
      .animate-title {
        animation: titleAnimation 1s forwards;
      }
    `}</style>
  </section>

  <section className="pt-4 pb-16 px-4">

              <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
                {cards.map((card, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2, duration: 0.8 }}
                    className="group relative overflow-hidden rounded-xl transition-all duration-500 hover:shadow-lg hover:shadow-blue-500/20"
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-500/30 to-purple-500/30 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="relative backdrop-blur-xl bg-white/10 p-6 h-full border border-white/10 group-hover:border-white/20 transition-colors">
                      <div className="mb-3 transform group-hover:scale-110 transition-transform">{card.icon}</div>
                      <h3 className="text-lg md:text-xl font-semibold mb-2 font-orbitron">{card.title}</h3>
                      <p className="text-sm md:text-base text-gray-300">{card.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </section>
            <Footer/>
            
  </>
  )
}

export default TermsandConditon;