import React from 'react';
import Hero from './Hero'
import Mission from './Mission';
import Offerings from './Offerings';
import Courses from './Courses';
import BackgroundAnimationTwo from './BackgroundAnimationTwo';
import Footer from './Footer';

const About = () => {
  return (
    <>
            <BackgroundAnimationTwo />
      <main className="aboutheading min-h-screen  text-white relative overflow-hidden font-['Exo 2']">

        <div className="relative z-10 space-y-8">
          <Hero />
          <Mission />
          <Offerings />
          <Courses />
          <Footer/>
        </div>
      </main>
    </>

  )
}

export default About