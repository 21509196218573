import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaTimes, FaPaperPlane } from "react-icons/fa"; 
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { AiOutlineComment } from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const Feedback = () => {
  const auth = getAuth();
  const db = getFirestore();
  const [showModal, setShowModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);

  useEffect(() => {
    fetchFeedback();
  }, []);

  const fetchFeedback = async () => {
    const querySnapshot = await getDocs(collection(db, "feedback"));
    const feedbackData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setFeedbackList(feedbackData);
  };

  const handleSubmit = async () => {
    if (!rating || !message) {
      toast.error("Please provide rating and message");
      return;
    }

    // Word count validation (500 words limit)
    const wordCount = message.split(/\s+/).length;
    if (wordCount > 500) {
      toast.error("Your message exceeds the 500-word limit.");
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      toast.error("User not logged in");
      return;
    }

    const feedbackData = {
      userId: user.uid,
      name: user.displayName || "Anonymous",
      photo: user.photoURL || "https://via.placeholder.com/50",
      rating,
      message,
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, "feedback"), feedbackData);
      fetchFeedback();
      setShowModal(false);
      setRating(0);
      setMessage("");
      toast.success("Feedback submitted successfully!");
    } catch (error) {
      toast.error("Error submitting feedback. Please try again.");
    }
  };

  return (
    <div className="p-6">
      <div className="flex flex-col items-center justify-center mt-8">
        <motion.h1
          className="event_hero-heading text-7xl font-bold text-white drop-shadow-3xl sm:text-5xl md:text-6xl"
          initial={{ scale: 0.8, rotateX: -30 }}
          animate={{ scale: 1, rotateX: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          style={{
            textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
            fontSize: "70px",
            fontFamily: "cursive",
            fontWeight: "bolder",
            textAlign: "center",
            marginTop: "90px"
          }}
        >
          Testimonial
        </motion.h1>

        <motion.button
          className="bg-[linear-gradient(45deg,_#00ffff,_#00ff88)] text-black p-4 rounded-full mt-4"
          onClick={() => setShowModal(true)}
          whileHover={{ scale: 1.1 }}
          
        >
          <AiOutlineComment className="text-2xl" />
        </motion.button>
      </div>

      {showModal && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="p-6 rounded-lg shadow-lg w-80 relative backdrop-blur-lg bg-blue-500 bg-opacity-30">
            <h2 className="text-lg font-bold">Rate Us</h2>
            <FaTimes
              className="absolute top-2 right-2 text-xl cursor-pointer"
              onClick={() => setShowModal(false)} // Close modal on click
            />
            <div className="flex mt-3">
              {[1, 2, 3, 4, 5].map((star) => (
                <span key={star} onClick={() => setRating(star)} className="cursor-pointer text-2xl">
                  {star <= rating ? <AiFillStar className="text-yellow-500" /> : <AiOutlineStar />}
                </span>
              ))}
            </div>
            <textarea
              className="w-full border p-2 mt-3"
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-400 px-4 py-2 rounded flex items-center justify-center"
              >
                <FaTimes className="mr-2" /> {/* Cross icon */}
                Close
              </button>
              <button
                onClick={handleSubmit}
                className="bg-blue-500 px-4 py-2 text-white rounded flex items-center justify-center"
              >
                <FaPaperPlane className="mr-2" /> {/* Submit icon */}
                Submit
              </button>
            </div>
          </div>
        </motion.div>
      )}

      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        modules={[Autoplay]}
        className="mt-6"
      >
        {feedbackList.map((feedback) => (
          <SwiperSlide key={feedback.id}>
<motion.div
  className="relative backdrop-blur-xl bg-white/10 p-6 h-full border border-white/10 group-hover:border-white/20 transition-all duration-500 rounded-lg flex flex-col items-center justify-center"
  whileHover={{
    scale: 1.05,
    margin: '10px',
  }}
  transition={{ type: 'spring', stiffness: 300 }}
>
  <img src={feedback.photo} alt={feedback.name} className="w-12 h-12 rounded-full mb-2" />
  <h3 className="font-semibold text-center mb-2">{feedback.name}</h3>
  <div className="flex mb-2">
    {[...Array(5)].map((_, i) => (
      <AiFillStar key={i} className={i < feedback.rating ? "text-yellow-500" : "text-gray-300"} />
    ))}
  </div>
  <p className="mt-2 text-sm text-center">{feedback.message}</p>
</motion.div>

          </SwiperSlide>
        ))}
      </Swiper>

     
      <ToastContainer />
    </div>
  );
};

export default Feedback;

