// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
// import styled from '@emotion/styled';
// import { FaInstagram, FaYoutube, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
// import { db } from './firebase'; // Import your Firebase configuration
// import { collection, addDoc } from 'firebase/firestore';

// const FooterContainer = styled.footer`
//   padding: 3rem 1rem 1.5rem;
//   background: rgba(0, 0, 0, 0.5);
//   position: relative;
//   overflow: hidden;
//   width: 100%;

//   @media (min-width: 768px) {
//     padding: 4rem 2rem 2rem;
//   }
// `;

// const FooterContent = styled.div`
//   width: 100%;
//   max-width: 1440px;
//   margin: 0 auto;
//   display: grid;
//   grid-template-columns: 1fr;
//   gap: 2rem;
//   padding: 0;

//   @media (min-width: 640px) {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   @media (min-width: 1024px) {
//     grid-template-columns: repeat(4, 1fr);
//     gap: 3rem;
//   }
// `;

// const FooterSection = styled.div`
//   color: #fff;
//   width: 100%;
// `;

// const FooterTitle = styled.h3`
//   font-size: 1.1rem;
//   margin-bottom: 1.2rem;
//   color: #00ffff;

//   @media (min-width: 768px) {
//     font-size: 1.2rem;
//     margin-bottom: 1.5rem;
//   }
// `;

// const FooterLink = styled(motion(Link))`
//   display: block;
//   color: rgba(255, 255, 255, 0.8);
//   margin-bottom: 0.8rem;
//   text-decoration: none;
//   cursor: pointer;

//   &:hover {
//     color: #00ffff;
//   }
// `;

// const SocialLinks = styled.div`
//   display: flex;
//   gap: 1rem;
//   margin-top: 1rem;
//   width: 100%;
//   justify-content: center;
// `;

// const SocialIcon = styled(motion.a)`
//   color: #fff;
//   font-size: 1.5rem;
//   cursor: pointer;
  
//   &:hover {
//     color: #00ffff;
//   }
// `;

// const Newsletter = styled.div`
//   margin-top: 1rem;
//   width: 100%;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 0.8rem;
//   background: rgba(255, 255, 255, 0.1);
//   border: 1px solid rgba(255, 255, 255, 0.2);
//   border-radius: 0.5rem;
//   color: white !important;
//   margin-bottom: 1rem;
//   font-size: 0.9rem;
  
//   @media (min-width: 768px) {
//     padding: 1rem;
//     font-size: 1rem;
//   }
  
//   &:focus {
//     outline: none;
//     border-color: #00ffff;
//   }
// `;

// const SubscribeButton = styled(motion.button)`
//   width: 100%;
//   padding: 0.8rem;
//   background: linear-gradient(45deg, #00ffff, #00ff88);
//   border: none;
//   border-radius: 0.5rem;
//   color: #000;
//   font-weight: 600;
//   cursor: pointer;
  
//   &:hover {
//     box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
//   }
// `;

// const Copyright = styled.div`
//   text-align: center;
//   margin-top: 2rem;
//   padding-top: 1.5rem;
//   border-top: 1px solid rgba(255, 255, 255, 0.1);
//   color: rgba(255, 255, 255, 0.6);
//   width: 100%;
//   max-width: 1440px;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 0.9rem;
//   padding: 1.5rem 1rem 0;

//   @media (min-width: 768px) {
//     margin-top: 3rem;
//     padding-top: 2rem;
//     font-size: 1rem;
//   }
// `;

// const Footer = () => {
//   const [email, setEmail] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [successMessage, setSuccessMessage] = useState('');

//   const handleSubscribe = async () => {
//     if (email.trim() === '') return;
//     setIsSubmitting(true);
//     try {
//       // Save email to Firestore
//       const docRef = await addDoc(collection(db, 'newsletter'), {
//         email: email,
//         timestamp: new Date()
//       });
//       setSuccessMessage('Subscription successful!');
//       setEmail('');
//     } catch (error) {
//       console.error('Error adding document: ', error);
//       setSuccessMessage('Something went wrong. Please try again.');
//     }
//     setIsSubmitting(false);
//   };

//   return (
//     <FooterContainer>
//       <FooterContent>
//         <FooterSection>
//           <FooterTitle>Quick Links</FooterTitle>
//           <FooterLink to="/" whileHover={{ x: 5 }}>Home</FooterLink>
//           <FooterLink to="/about" whileHover={{ x: 5 }}>About</FooterLink>
//           <FooterLink to="/contact" whileHover={{ x: 5 }}>Contact</FooterLink>
//           <FooterLink to="/profile" whileHover={{ x: 5 }}>Profile</FooterLink>
//         </FooterSection>

//         <FooterSection>
//           <FooterTitle>Helpful Links</FooterTitle>
//           <FooterLink to="/tech-library" whileHover={{ x: 5 }}>Tech Library</FooterLink>
//           <FooterLink to="/event" whileHover={{ x: 5 }}>Events</FooterLink>
//           <FooterLink to="/privacy-policy" whileHover={{ x: 5 }}>Privacy Policy</FooterLink>
//           <FooterLink to="/terms & condition" whileHover={{ x: 5 }}>Terms & Conditions</FooterLink>
//           <FooterLink to="/feedback" whileHover={{ x: 5 }}>Feedback</FooterLink>
//           {/* <FooterLink to="/faq" whileHover={{ x: 5 }}>FAQs</FooterLink> */}
//         </FooterSection>

//         <FooterSection>
//           <FooterTitle>Get in Touch</FooterTitle>
//           <FooterLink href="mailto:snaped4338@gmail.com" whileHover={{ x: 5 }}>snaped4338@gmail.com</FooterLink>
//           <FooterLink href="mailto:infinity0061@gmail.com" whileHover={{ x: 5 }}>infinity0061@gmail.com</FooterLink>
//         </FooterSection>

//         <FooterSection>
//           <FooterTitle>Connect With Us</FooterTitle>
//           <SocialLinks>
//             <SocialIcon
//               as={motion.a}
//               whileHover={{ y: -3 }}
//               href="https://www.instagram.com/snapedcodecampus"
//               target="_blank"
//             >
//               <FaInstagram />
//             </SocialIcon>
//             <SocialIcon
//               as={motion.a}
//               whileHover={{ y: -3 }}
//               href="https://youtube.com/@snapedcodecampus"
//               target="_blank"
//             >
//               <FaYoutube />
//             </SocialIcon>
//             <SocialIcon
//               as={motion.a}
//               whileHover={{ y: -3 }}
//               href="https://www.linkedin.com/company/snaped-code-campus"
//               target="_blank"
//             >
//               <FaLinkedin />
//             </SocialIcon>
//             <SocialIcon
//               as={motion.a}
//               whileHover={{ y: -3 }}
//               href="https://wa.me/9871391381"
//               target="_blank"
//             >
//               <FaWhatsapp />
//             </SocialIcon>
//           </SocialLinks>

//           <Newsletter>
//             <FooterTitle>Newsletter</FooterTitle>
//             <Input
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <SubscribeButton
//               whileHover={{ scale: 1.02 }}
//               whileTap={{ scale: 0.98 }}
//               onClick={handleSubscribe}
//               disabled={isSubmitting}
//             >
//               {isSubmitting ? 'Subscribing...' : 'Subscribe'}
//             </SubscribeButton>
//             {successMessage && <p>{successMessage}</p>}
//           </Newsletter>
//         </FooterSection>
//       </FooterContent>

//       <Copyright>
//         © 2024 SnapED CodeCampus. All rights reserved.
//       </Copyright>
//     </FooterContainer>
//   );
// };

// export default Footer;



import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { FaInstagram, FaYoutube, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

const FooterContainer = styled.footer`
  padding: 3rem 1rem 1.5rem;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (min-width: 768px) {
    padding: 4rem 2rem 2rem;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 0;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
  }
`;

const FooterSection = styled.div`
  color: #fff;
  width: 100%;
`;

const FooterTitle = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 1.2rem;
  color: #00ffff;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
`;

const FooterLink = styled(motion(Link))`
  display: block;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.8rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #00ffff;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
`;

const SocialIcon = styled(motion.a)`
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  
  &:hover {
    color: #00ffff;
  }
`;

const Newsletter = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  color: white !important;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  
  @media (min-width: 768px) {
    padding: 1rem;
    font-size: 1rem;
  }
  
  &:focus {
    outline: none;
    border-color: #00ffff;
  }
`;

const SubscribeButton = styled(motion.button)`
  width: 100%;
  padding: 0.8rem;
  background: linear-gradient(45deg, #00ffff, #00ff88);
  border: none;
  border-radius: 0.5rem;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9rem;
  padding: 1.5rem 1rem 0;

  @media (min-width: 768px) {
    margin-top: 3rem;
    padding-top: 2rem;
    font-size: 1rem;
  }
`;

const AdContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
  min-height: 100px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showAd, setShowAd] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    // Load Google AdSense script
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2887238820446530';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleSubscribe = async () => {
    if (email.trim() === '') return;
    setIsSubmitting(true);
    try {
      const docRef = await addDoc(collection(db, 'newsletter'), {
        email: email,
        timestamp: new Date()
      });
      setSuccessMessage('Subscription successful!');
      setEmail('');
    } catch (error) {
      console.error('Error adding document: ', error);
      setSuccessMessage('Something went wrong. Please try again.');
    }
    setIsSubmitting(false);
  };

  const handleLinkClick = () => {
    setClickCount(prev => prev + 1);
    
    // Show ad only after every 3 clicks to comply with Google's policies
    if (clickCount >= 2) { // 0, 1, 2 (3rd click)
      setShowAd(true);
      setClickCount(0);
      
      // Hide ad after 30 seconds
      setTimeout(() => {
        setShowAd(false);
      }, 30000);
    }
  };

  const renderAd = () => {
    if (!showAd) return null;

    return (
      <AdContainer>
        <ins 
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-2887238820446530"
          data-ad-slot="1502817186"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script dangerouslySetInnerHTML={{ __html: '(adsbygoogle = window.adsbygoogle || []).push({});' }} />
      </AdContainer>
    );
  };

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <FooterLink to="/" whileHover={{ x: 5 }} onClick={handleLinkClick}>Home</FooterLink>
          <FooterLink to="/about" whileHover={{ x: 5 }} onClick={handleLinkClick}>About</FooterLink>
          <FooterLink to="/contact" whileHover={{ x: 5 }} onClick={handleLinkClick}>Contact</FooterLink>
          <FooterLink to="/profile" whileHover={{ x: 5 }} onClick={handleLinkClick}>Profile</FooterLink>
          {renderAd()}
        </FooterSection>

        <FooterSection>
          <FooterTitle>Helpful Links</FooterTitle>
          <FooterLink to="/tech-library" whileHover={{ x: 5 }} onClick={handleLinkClick}>Tech Library</FooterLink>
          <FooterLink to="/event" whileHover={{ x: 5 }} onClick={handleLinkClick}>Events</FooterLink>
          <FooterLink to="/privacy-policy" whileHover={{ x: 5 }} onClick={handleLinkClick}>Privacy Policy</FooterLink>
          <FooterLink to="/terms & condition" whileHover={{ x: 5 }} onClick={handleLinkClick}>Terms & Conditions</FooterLink>
          <FooterLink to="/feedback" whileHover={{ x: 5 }} onClick={handleLinkClick}>Feedback</FooterLink>
          {renderAd()}
        </FooterSection>

        <FooterSection>
          <FooterTitle>Get in Touch</FooterTitle>
          <FooterLink as="a" href="mailto:snaped4338@gmail.com" whileHover={{ x: 5 }} onClick={handleLinkClick}>snaped4338@gmail.com</FooterLink>
          <FooterLink as="a" href="mailto:infinity0061@gmail.com" whileHover={{ x: 5 }} onClick={handleLinkClick}>infinity0061@gmail.com</FooterLink>
          {renderAd()}
        </FooterSection>

        <FooterSection>
          <FooterTitle>Connect With Us</FooterTitle>
          <SocialLinks>
            <SocialIcon
              as={motion.a}
              whileHover={{ y: -3 }}
              href="https://www.instagram.com/snapedcodecampus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </SocialIcon>
            <SocialIcon
              as={motion.a}
              whileHover={{ y: -3 }}
              href="https://youtube.com/@snapedcodecampus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </SocialIcon>
            <SocialIcon
              as={motion.a}
              whileHover={{ y: -3 }}
              href="https://www.linkedin.com/company/snaped-code-campus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </SocialIcon>
            <SocialIcon
              as={motion.a}
              whileHover={{ y: -3 }}
              href="https://wa.me/9871391381"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </SocialIcon>
          </SocialLinks>

          <Newsletter>
            <FooterTitle>Newsletter</FooterTitle>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <SubscribeButton
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleSubscribe}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Subscribing...' : 'Subscribe'}
            </SubscribeButton>
            {successMessage && <p style={{ color: '#00ff88', marginTop: '0.5rem' }}>{successMessage}</p>}
          </Newsletter>
          {renderAd()}
        </FooterSection>
      </FooterContent>

      <Copyright>
        © 2024 SnapED CodeCampus. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
