import React, { useState } from "react";
import { 
  signInWithEmailAndPassword,
  sendPasswordResetEmail 
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../components/firebase";
import SignInwithGoogle from "./signInWithGoogle";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("🎉 Login Successful! Welcome to SnapED 🎓", {
        position: "top-center",
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      toast.error(`❌ Login Failed: ${error.message}`, {
        position: "bottom-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      toast.error("Please enter your email address first", {
        position: "bottom-center",
      });
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(`Password reset email sent to ${email}`, {
        position: "bottom-center",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`Error sending reset email: ${error.message}`, {
        position: "bottom-center",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <section className="h-screen bg-gray-900">
        <div className="container h-full px-6 py-24">
          <div className="flex h-full flex-wrap items-center justify-center lg:justify-between">
            {/* Left column container with background*/}
            <div className="mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
              <img
                src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                className="w-full"
                alt="Phone image"
              />
            </div>

            {/* Right column container with form */}
            <div className="md:w-8/12 lg:ms-6 lg:w-5/12">
              <div className="rounded-lg bg-gray-900 p-12 shadow-lg">
                             {/* Motivational Header */}
                             <div className="mb-8 text-center">
                  <h2 className="text-3xl font-bold text-white mb-2">Welcome Back!</h2>
                  <p className="text-blue-200 text-lg">
                    Your next breakthrough starts here. Let's make today productive!
                  </p>
                </div>
                <form onSubmit={handleEmailLogin}>
                  {/* Email input */}
                  <div className="relative mb-6">
                    <input
                      type="email"
                      className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear border-b border-gray-300 focus:border-blue-400 text-white"
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setIsEmailFocused(true)}
                      onBlur={() => setIsEmailFocused(false)}
                      required
                    />
                    <label
                      htmlFor="emailInput"
                      className={`pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-gray-300 transition-all duration-200 ease-out ${
                        email || isEmailFocused
                          ? "-translate-y-[1.15rem] scale-[0.8] text-blue-300"
                          : ""
                      }`}
                    >
                      Email address
                    </label>
                  </div>

                  {/* Password input */}
                  <div className="relative mb-6">
                    <input
                      type="password"
                      className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear border-b border-gray-300 focus:border-blue-400 text-white"
                      id="passwordInput"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={() => setIsPasswordFocused(false)}
                      required
                    />
                    <label
                      htmlFor="passwordInput"
                      className={`pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-gray-300 transition-all duration-200 ease-out ${
                        password || isPasswordFocused
                          ? "-translate-y-[1.15rem] scale-[0.8] text-blue-300"
                          : ""
                      }`}
                    >
                      Password
                    </label>
                  </div>

                  {/* Forgot password link */}
                  <div className="mb-6 text-right">
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className="text-blue-300 hover:text-blue-100 focus:outline-none transition-colors duration-200"
                    >
                      Forgot password?
                    </button>
                  </div>

                  {/* Submit button */}
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`inline-block w-full rounded px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out focus:outline-none focus:ring-0 ${
                      isLoading
                        ? "bg-blue-400 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600 hover:shadow-lg active:bg-blue-700"
                    }`}
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </span>
                    ) : (
                      "Sign in"
                    )}
                  </button>

                  {/* Divider */}
                  <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-400 after:mt-0.5 after:flex-1 after:border-t after:border-gray-400">
                    <p className="mx-4 mb-0 text-center font-semibold text-gray-300">OR</p>
                  </div>

                  {/* Social login buttons */}
                  <SignInwithGoogle />

                  {/* Register link */}
                  <div className="mt-6 text-center">
                    <p className="text-gray-300">
                      Don't have an account?{" "}
                      <Link
                        to="/signup"
                        className="text-blue-300 hover:text-blue-100 transition-colors duration-200 "
                      >
                        Create one Now
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Login;