import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth, db } from "../../components/firebase";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";

function Profile() {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [borderColor, setBorderColor] = useState("gray");

  const navigate = useNavigate();

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("User is not logged in");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Wait for the sign-out to complete
      toast.success("Logout Successfully!");
      navigate("/"); // Navigate to the home page after sign-out is complete
    } catch (error) {
      toast.error("Error logging out: " + error.message);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      toast.success("Image chosen successfully: " + e.target.files[0].name);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error("Please choose a file first!");
      return;
    }

    try {
      setUploading(true);
      setUploadProgress(0);

      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result;
        const user = auth.currentUser;

        if (user) {
          const docRef = doc(db, "Users", user.uid);

          for (let progress = 0; progress <= 100; progress += 10) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            setUploadProgress(progress);
            setBorderColor("rgba(0, 123, 255, 0.5)"); // Transparent blue
          }

          await updateDoc(docRef, { photo: base64Image });

          setUserDetails((prevDetails) => ({
            ...prevDetails,
            photo: base64Image,
          }));

          setBorderColor("green");
          toast.success("Profile photo uploaded successfully!");

          setTimeout(() => setBorderColor("gray"), 2000);
        }
      };

      reader.onerror = () => {
        toast.error("Error reading the file. Please try again.");
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      toast.error("Error uploading file: " + error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
    <div className="flex flex-col items-center min-h-screen p-4 lg:mt-[150px] lg:bg-transparent">
      <ToastContainer position="top-right" autoClose={3000} />

      {loading ? (
        <p className="text-lg font-medium text-gray-700">Loading...</p>
      ) : userDetails ? (
        <motion.div
          className="w-full max-w-md bg-white rounded-lg shadow-lg p-6"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="flex flex-col items-center"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              style={{
                border: `4px solid ${borderColor}`,
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                perspective: "1000px",
              }}
              whileHover={{ rotateY: 15, rotateX: 15 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              {userDetails.photo ? (
                <motion.img
                  alt="profileImage"
                  src={userDetails.photo}
                  className="w-24 h-24 rounded-full"
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.2 }}
                />
              ) : (
                <p className="text-gray-500">No Profile Image</p>
              )}
              {uploading && (
                <motion.div
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    textAlign: "center",
                    color: "rgba(0, 123, 255, 0.8)",
                    fontWeight: "bold",
                  }}
                  animate={{ opacity: [0, 1, 0] }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                >
                  {uploadProgress}%
                </motion.div>
              )}
            </motion.div>

            <h3 className="mt-4 text-2xl font-semibold text-gray-800">
              Welcome {userDetails.firstName} 🙏
            </h3>
            <p className="text-gray-600">Email: {userDetails.email}</p>
          </motion.div>

          <motion.div
            className="mt-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <label
              htmlFor="file-input"
              className="block text-center text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-md cursor-pointer"
            >
              Choose Image
            </label>
            <input
              type="file"
              id="file-input"
              className="hidden"
              onChange={handleFileChange}
              accept="image/*"
            />

            <motion.button
              className={`w-full mt-4 px-4 py-2 text-white font-medium rounded-md transition duration-300 ${
                uploading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600"
              }`}
              onClick={handleUpload}
              disabled={uploading || !file}
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            >
              {uploading ? "Uploading..." : "Upload Image"}
            </motion.button>
          </motion.div>

          <motion.button
            className="w-full mt-6 px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded-md"
            onClick={handleLogout}
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            Logout
          </motion.button>
        </motion.div>
      ) : (
        <p className="text-lg font-medium text-gray-700">No user data found</p>
      )}
      
    </div>
    <Footer/>
    </>
  );
}

export default Profile;
