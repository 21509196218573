import React from 'react'
import heroImg from '../assets/Hero_section.gif'

const HeroSection = () => {
  return (
    <section className='w-full text-white py-24 p-4'>
        <div className='md:max-w-[1100px] m-auto grid md:grid-cols-2 max-w-[400px]'>
            <div className='flex flex-col justify-start gap-4'>
                <p className='py-2 text-4xl text-[#208486] font-bold'>BUILD. LEARN. GROW.</p>
                <h1 className='md:leading-[42px] py-2 md:text-3xl text-lg font-semibold'>
                    Join <span className='text-[#208486]'>SnapED codeCampus</span>—Your gateway to <span className='text-[#208486]'>top-notch learning</span>, expert guidance, and real-world projects!
                </h1>
                <p className='py-2 text-lg '>Master coding, crack your exams, and upskill with industry-relevant courses.</p>
            </div>
            <img src={heroImg} alt="hero" className='w-full md:order-last order-first'/>
        </div>
    </section>
  )
}

export default HeroSection;
