// // Probability Statistics and Linear Programming.js

// import React, { useState, useEffect } from 'react';
// import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
// import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
// import { auth, db } from '../../../components/firebase';
// import Tabs from '../../../components/Tabs';
// import Card from '../../../components/notescard';
// import Accordions from '../../../components/Dropdowns';

// const PSLP = () => {
//   const [user, setUser] = useState(null);
//   const [apNotes, setApNotes] = useState([]);
//   const [videos, setVideos] = useState([]); // State for videos
//   const [videoTitle, setVideoTitle] = useState('');
//   const [videoUrl, setVideoUrl] = useState('');
//   const [driveLink, setDriveLink] = useState(''); // Google Drive link for file
//   const [driveTitle, setDriveTitle] = useState(''); // Title for Google Drive link
//   const adminEmail = 'snaped4338@gmail.com';

//   // Fetch notes from Firestore
//   useEffect(() => {
//     const fetchNotesFromFirestore = async () => {
//       const notesSnapshot = await getDocs(collection(db, 'PSLPNotes'));
//       setApNotes(notesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
//     };
//     fetchNotesFromFirestore();
//   }, []);

//   // Fetch videos from Firestore
//   useEffect(() => {
//     const fetchVideos = async () => {
//       const videoCollection = collection(db, 'PSLPVideos');
//       const videoSnapshot = await getDocs(videoCollection);
//       setVideos(videoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
//     };
//     fetchVideos();
//   }, []);

//   // Monitor user authentication state
//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       setUser(user);
//     });
//     return () => unsubscribe();
//   }, []);

//   // Google Sign-In
//   const handleGoogleLogin = async () => {
//     const provider = new GoogleAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       setUser(result.user);
//     } catch (error) {
//       console.error('Error during Google login:', error);
//     }
//   };

//   // File upload handler (using Firestore for Google Drive link)
//   const handleDriveLinkUpload = (category) => {
//     if (!driveLink || !driveTitle) {
//       alert('Please provide both a valid title and Google Drive link.');
//       return;
//     }

//     const newNote = { title: driveTitle, category, link: driveLink };
//     addDoc(collection(db, 'PSLPNotes'), newNote).then(() => {
//       setApNotes((prevNotes) => [...prevNotes, newNote]);
//       alert('Google Drive link uploaded successfully!');
//       setDriveLink(''); // Clear the input after uploading
//       setDriveTitle(''); // Clear the title input after uploading
//     });
//   };

//   // Handle deleting a note
//   const handleDeleteNote = async (note) => {
//     const noteDoc = doc(db, 'PSLPNotes', note.id);
//     await deleteDoc(noteDoc);
//     setApNotes(apNotes.filter((n) => n.id !== note.id)); // Remove from state
//   };

  
// // Handle adding a video
// const handleAddVideo = async () => {
//   if (!videoTitle || !videoUrl) {
//     alert('Please provide both a valid title and video URL.');
//     return;
//   }

//   // Convert YouTube URL to embed format (either for normal video, live stream, or playlist)
//   let formattedUrl = '';

//   // Check if it's a YouTube live stream
//   if (videoUrl.includes('youtube.com/live')) {
//     const videoId = videoUrl.split('/').pop();
//     formattedUrl = `https://www.youtube.com/embed/${videoId}`;
//   }
//   // Check if it's a YouTube regular video
//   else if (videoUrl.includes('youtube.com/watch?v=')) {
//     const videoId = new URL(videoUrl).searchParams.get('v');
//     formattedUrl = `https://www.youtube.com/embed/${videoId}`;
//   }
//   // Check if it's a YouTube playlist
//   else if (videoUrl.includes('youtube.com/playlist?list=')) {
//     const playlistId = new URL(videoUrl).searchParams.get('list');
//     formattedUrl = `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
//   } else {
//     alert('Please provide a valid YouTube video, live stream, or playlist URL.');
//     return;
//   }

//   const newVideo = { title: videoTitle, link: formattedUrl };

//   // Add video to Firestore
//   const videoRef = await addDoc(collection(db, 'PSLPVideos'), newVideo);

//   // Update state with new video
//   setVideos((prevVideos) => [...prevVideos, { id: videoRef.id, ...newVideo }]);
//   setVideoTitle(''); // Clear title input after adding video
//   setVideoUrl(''); // Clear URL input after adding video
// };

// // Handle deleting a video
// const handleDeleteVideo = async (videoId) => {
//   const videoDoc = doc(db, 'PSLPVideos', videoId);
//   await deleteDoc(videoDoc);
//   setVideos(videos.filter((video) => video.id !== videoId)); // Remove from state
// };

//   // Render upload button with Google Drive link input
//   const renderUploadButton = (category) => (
//     <div className="upload-container">
//       {user?.email === adminEmail && (
//         <div>
//           <input
//             type="text"
//             placeholder="Note Title"
//             value={driveTitle}
//             onChange={(e) => setDriveTitle(e.target.value)}
//             style={{ marginRight: '10px' }}
//           />
//           <input
//             type="text"
//             placeholder="Google Drive Link"
//             value={driveLink}
//             onChange={(e) => setDriveLink(e.target.value)}
//             style={{ marginRight: '10px' }}
//           />
//           <button onClick={() => handleDriveLinkUpload(category)}>Upload Link</button>
//         </div>
//       )}
//     </div>
//   );

//   // Render cards with iframe for displaying PDFs from Google Drive links
//   const renderCards = (category) => (
//     <div className="cards-container">
//       {apNotes
//         .filter((note) => note.category === category)
//         .map((note, index) => (
//           <Card
//             key={index}
//             title={note.title}
//             link={note.link}
//             onDelete={() => handleDeleteNote(note)}
//             showDelete={user?.email === adminEmail}
//             user={user}
//           >
//             {/* Display PDF using iframe for Google Drive links */}
//             {note.link && (
//               <iframe
//                 src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${note.link}`}
//                 width="100%"
//                 height="500px"
//                 frameBorder="0"
//                 title={note.title}
//               />
//             )}
//           </Card>
//         ))}
//     </div>
//   );


// // Render video section
// const renderVideoSection = () => (
//   <div>
//     {user?.email === adminEmail && (
//       <div style={{ marginBottom: '20px', color: '#000'}}>
//         <input
//           type="text"
//           placeholder="Video Title"
//           value={videoTitle}
//           onChange={(e) => setVideoTitle(e.target.value)}
//           style={{ marginRight: '10px' }}
//         />
//         <input
//           type="text"
//           placeholder="YouTube Video, Live Stream, or Playlist URL"
//           value={videoUrl}
//           onChange={(e) => setVideoUrl(e.target.value)}
//           style={{ marginRight: '10px' }}
//         />
//         <button onClick={handleAddVideo}>Add Video</button>
//       </div>
//     )}
//     <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
//       {videos.map((video) => (
//         <div
//           key={video.id}
//           style={{
//             border: '1px solid #ccc',
//             padding: '10px',
//             borderRadius: '8px',
//             width: '320px',
//           }}
//         >
//           <h5>{video.title}</h5>
//           <iframe
//             src={video.link}
//             title={video.title}
//             width="300"
//             height="200"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           />
//           {user?.email === adminEmail && (
//             <button
//               onClick={() => handleDeleteVideo(video.id)}
//               style={{ marginTop: '10px' }}
//             >
//               Delete
//             </button>
//           )}
//         </div>
//       ))}
//     </div>
//   </div>
// );


//   const accordionContent = [
//     <div key="1">
//       <p>Basics: Probability and Statistical models, Sample Spaces and Events, Counting Techniques, Interpretations and Axioms of Probability, Unions of Events and Addition Rules, Conditional Probability, Intersections of Events and Multiplication and Total Probability Rules, Independence, Bayes’ Theorem, Random Variables. Discrete and Continuous Random Variables and Distributions: Probability Distributions and Probability Mass / density Functions, Cumulative Distribution Functions, Mean and Variance of a Random Variable, Discrete and continuous Uniform Distribution, Binomial Distribution, Geometric and Negative Binomial Distributions, Hypergeometric Distribution, Poisson Distribution. Normal Distribution, Normal Approximation to the Binomial, and Poisson Distributions; Exponential Distribution, Erlang and Gamma Distributions, Weibull Distribution, Lognormal Distribution, Beta Distribution.</p>
//     </div>,
//     <div key="2">
//       <p>Joint Probability Distributions for Two Random Variables, Conditional Probability Distributions and Independence, Joint Probability Distributions for Two Random Variables, Covariance and Correlation, Common Joint Distributions, Linear Functions of RandomVariables, General Functions of Random Variables, Moment-Generating Functions. Numerical Summaries of Data, Stem-and-Leaf Diagrams, Frequency Distributions and Histograms, Box Plots, Time Sequence Plots, Scatter Diagrams, Probability Plots. Point Estimation, Sampling Distributions and the Central Limit Theorem without proof, General Concepts of Point Estimation, Methods of Point Estimation, Statistical Intervals for a Single Sample.</p>
//     </div>,
//         <div key="3">
//         <p>Hypotheses Testing for a SingleSample: Tests on the Mean of a Normal Distribution with Variance Known / Unknown, Tests on the Variance and Standard Deviationof a Normal Distribution, Tests on a Population Proportion, Testing for Goodness of Fit, Nonparametric tests (Signed, Wilcoxon), Similarly Statistical Inference forTwo Samples. Regression and Correlation: Linear Regression, Least Squares Estimators, Hypotheses testing for simple linear regression, Confidence Intervals, Adequacy of model, Correlation, Transformed Variables, Logistic Regression. Similarly, for multiple linear regression including aspects of MLR. </p>
//       </div>,
//       <div key="4">
//         <p>Linear Programming: Introduction, formulation of problem, Graphical method, Canonical and Standard form of LPP, Simplex method, Duality concept, Dual simplex method, Transportation and Assignment problem.</p>
//       </div>,
    
    
//   ];

//   const tabData = [
//     {
//       id: 'syllabus',
//       label: 'Syllabus',
//       content: <Accordions accordionContent={accordionContent} />,
//     },
//     {
//       id: 'notes',
//       label: 'Notes',
//       content: (
//         <>
//           {renderUploadButton('notes')}
//           {renderCards('notes')}
//         </>
//       ),
//     },
//     {
//       id: 'important',
//       label: 'Important',
//       content: (
//         <>
//           {renderUploadButton('important')}
//           {renderCards('important')}
//         </>
//       ),
//     },
//     {
//       id: 'books',
//       label: 'Books',
//       content: (
//         <>
//           {renderUploadButton('books')}
//           {renderCards('books')}
//         </>
//       ),
//     },

//     {
//       id: 'PYQ',
//       label: 'PYQ',
//       content: (
//         <>
//           {renderUploadButton('pyq')}
//           {renderCards('pyq')}
//         </>
//       ),
//     },
//     {
//       id: 'youtube_playlist',
//       label: 'YouTube Videos',
//       content: renderVideoSection(),
//     },
//   ];

//   return (
//     <section>
//       <h1 className='Subjectname'> Probability Statistics and Linear Programming </h1>
//       {!user ? (
//         <button onClick={handleGoogleLogin}>Login with Google</button>
//       ) : (
//         <Tabs tabData={tabData} />
//       )}
//     </section>
//   );
// };

// export default PSLP;

import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../components/firebase';
import Tabs from '../../../components/Tabs';
import { 
  FaFilePdf, 
  FaYoutube, 
  FaGoogleDrive, 
  FaTrash, 
  FaSignInAlt, 
  FaTimes, 
  FaChevronLeft, 
  FaChevronRight,
  FaWhatsapp,
  FaCopy,
  FaUpload,
  FaExternalLinkAlt,
  FaDownload
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import Accordions from '../../../components/Dropdowns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Helper function to extract file ID from Google Drive URLs
const extractFileId = (url) => {
  const patterns = [
    /\/file\/d\/([^\/]+)/,
    /\/d\/([^\/]+)/,
    /id=([^&]+)/,
    /\/folders\/([^\/]+)/,
    /[-\w]{25,}/
  ];

  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  return url;
};

const PSLP = () => {
  const [user, setUser] = useState(null);
  const [pslpNotes, setPslpNotes] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [driveLink, setDriveLink] = useState('');
  const [driveTitle, setDriveTitle] = useState('');
  const [pdfModal, setPdfModal] = useState({
    isOpen: false,
    currentIndex: 0,
    category: null
  });
  const [showUploadForm, setShowUploadForm] = useState(false);
  const adminEmail = 'snaped4338@gmail.com';
  
  // Google Form link for uploads
  const uploadFormLink = "https://forms.gle/a2LraBNUDBKqMJKW9";

  // Load Google Ads script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2887238820446530';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Show Google Ad
  const showAd = () => {
    try {
      if (window.adsbygoogle) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {
      console.error('Error showing ad:', error);
    }
  };

  // Fetch notes from Firestore
  useEffect(() => {
    const fetchNotesFromFirestore = async () => {
      const notesSnapshot = await getDocs(collection(db, 'PSLPNotes'));
      setPslpNotes(notesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchNotesFromFirestore();
  }, []);

  // Fetch videos from Firestore
  useEffect(() => {
    const fetchVideos = async () => {
      const videoCollection = collection(db, 'PSLPVideos');
      const videoSnapshot = await getDocs(videoCollection);
      setVideos(videoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchVideos();
  }, []);

  // Monitor user authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Open PDF modal and set current index
  const openPdfModal = (category, index) => {
    setPdfModal({
      isOpen: true,
      currentIndex: index,
      category: category
    });
    showAd();
  };

  // Navigate between PDFs in the modal
  const navigatePdf = (direction) => {
    const filteredNotes = pslpNotes.filter((note) => note.category === pdfModal.category);
    let newIndex;
    
    if (direction === 'prev') {
      newIndex = pdfModal.currentIndex === 0 ? filteredNotes.length - 1 : pdfModal.currentIndex - 1;
    } else {
      newIndex = pdfModal.currentIndex === filteredNotes.length - 1 ? 0 : pdfModal.currentIndex + 1;
    }
    
    setPdfModal(prev => ({
      ...prev,
      currentIndex: newIndex
    }));
    showAd();
  };

  // Close PDF modal
  const closePdfModal = () => {
    setPdfModal({
      isOpen: false,
      currentIndex: 0,
      category: null
    });
  };

  // Copy PDF link to clipboard
  const copyPdfLink = (link) => {
    navigator.clipboard.writeText(link);
    toast.success('Link copied to clipboard!');
    showAd();
  };

  // Share PDF via WhatsApp
  const shareViaWhatsApp = (link, title) => {
    const message = `Check out this PSLP PDF: ${title}\n\n${link}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    showAd();
  };

  // Download PDF
  const downloadPdf = (link, title) => {
    const fileId = extractFileId(link);
    const downloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
    
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${title}.pdf` || 'pslp-notes.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    
    toast.success('Download started!');
    showAd();
  };

  // Google Sign-In
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      showAd();
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  // File upload handler
  const handleDriveLinkUpload = (category) => {
    if (!driveLink || !driveTitle) {
      toast.warning('Please provide both a valid title and Google Drive link.');
      return;
    }

    const fileId = extractFileId(driveLink);
    if (!fileId) {
      toast.warning('Please provide a valid Google Drive link');
      return;
    }

    const newNote = { 
      title: driveTitle, 
      category, 
      link: `https://drive.google.com/file/d/${fileId}/view?usp=sharing`,
      directPreviewLink: `https://drive.google.com/file/d/${fileId}/preview`,
      downloadLink: `https://drive.google.com/uc?export=download&id=${fileId}`
    };
    
    addDoc(collection(db, 'PSLPNotes'), newNote).then((docRef) => {
      setPslpNotes((prevNotes) => [...prevNotes, { ...newNote, id: docRef.id }]);
      toast.success('PSLP notes uploaded successfully!');
      setDriveLink('');
      setDriveTitle('');
      showAd();
    }).catch(error => {
      console.error('Error uploading document: ', error);
      toast.error('Error uploading document. Please try again.');
    });
  };

  // Handle deleting a note
  const handleDeleteNote = async (note) => {
    if (window.confirm('Are you sure you want to delete this PSLP note?')) {
      try {
        const noteDoc = doc(db, 'PSLPNotes', note.id);
        await deleteDoc(noteDoc);
        setPslpNotes(pslpNotes.filter((n) => n.id !== note.id));
        
        if (pdfModal.isOpen && pdfModal.currentNoteId === note.id) {
          closePdfModal();
        }
        toast.success('Note deleted successfully');
        showAd();
      } catch (error) {
        console.error('Error deleting document: ', error);
        toast.error('Error deleting document. Please try again.');
      }
    }
  };

  // Handle adding a video
  const handleAddVideo = async () => {
    if (!videoTitle || !videoUrl) {
      toast.warning('Please provide both a valid title and video URL.');
      return;
    }

    let formattedUrl = '';
    if (videoUrl.includes('youtube.com/live')) {
      const videoId = videoUrl.split('/').pop();
      formattedUrl = `https://www.youtube.com/embed/${videoId}`;
    } else if (videoUrl.includes('youtube.com/watch?v=')) {
      const videoId = new URL(videoUrl).searchParams.get('v');
      formattedUrl = `https://www.youtube.com/embed/${videoId}`;
    } else if (videoUrl.includes('youtube.com/playlist?list=')) {
      const playlistId = new URL(videoUrl).searchParams.get('list');
      formattedUrl = `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
    } else {
      toast.warning('Please provide a valid YouTube video, live stream, or playlist URL.');
      return;
    }

    try {
      const newVideo = { title: videoTitle, link: formattedUrl };
      const videoRef = await addDoc(collection(db, 'PSLPVideos'), newVideo);
      setVideos((prevVideos) => [...prevVideos, { id: videoRef.id, ...newVideo }]);
      setVideoTitle('');
      setVideoUrl('');
      toast.success('PSLP video added successfully!');
      showAd();
    } catch (error) {
      console.error('Error adding video: ', error);
      toast.error('Error adding video. Please try again.');
    }
  };

  // Handle deleting a video
  const handleDeleteVideo = async (videoId) => {
    if (window.confirm('Are you sure you want to delete this PSLP video?')) {
      try {
        const videoDoc = doc(db, 'PSLPVideos', videoId);
        await deleteDoc(videoDoc);
        setVideos(videos.filter((video) => video.id !== videoId));
        toast.success('Video deleted successfully');
        showAd();
      } catch (error) {
        console.error('Error deleting video: ', error);
        toast.error('Error deleting video. Please try again.');
      }
    }
  };

  // Render upload button with Google Drive link input
  const renderUploadButton = (category) => (
    <motion.div 
      className="mb-8"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {user?.email === adminEmail && (
        <div className="flex flex-wrap gap-4 items-center mb-6">
          <input
            type="text"
            placeholder="Note Title"
            value={driveTitle}
            onChange={(e) => setDriveTitle(e.target.value)}
            className="flex-1 min-w-[200px] p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Google Drive Link"
            value={driveLink}
            onChange={(e) => setDriveLink(e.target.value)}
            className="flex-1 min-w-[200px] p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <motion.button 
            onClick={() => handleDriveLinkUpload(category)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="flex items-center gap-2 px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
          >
            <FaGoogleDrive /> Upload Link
          </motion.button>
          <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2887238820446530"
            data-ad-slot="1502817186"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      )}
    </motion.div>
  );

  // Render empty state with upload option
  const renderEmptyState = (category) => (
    <motion.div 
      className="flex flex-col items-center justify-center gap-4 p-8 text-gray-500"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <FaFilePdf size={48} className="text-gray-300" />
      <p className="text-lg">No {category} uploaded yet</p>
      
      <div className="flex flex-col sm:flex-row gap-4 mt-4">
        {user?.email === adminEmail ? (
          <>
            <motion.button
              onClick={() => setShowUploadForm(true)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              <FaUpload /> Upload Now
            </motion.button>
            <motion.a
              href={uploadFormLink}
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              <FaExternalLinkAlt /> Request Upload
            </motion.a>
          </>
        ) : (
          <motion.a
            href={uploadFormLink}
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
          >
            <FaExternalLinkAlt /> Request Upload via Form
          </motion.a>
        )}
      </div>
      
      {showUploadForm && (
        <motion.div 
          className="mt-6 w-full max-w-md bg-white p-6 rounded-lg shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">Upload {category}</h3>
            <button 
              onClick={() => setShowUploadForm(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
              <input
                type="text"
                placeholder={`${category} title`}
                value={driveTitle}
                onChange={(e) => setDriveTitle(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Google Drive Link</label>
              <input
                type="text"
                placeholder="Paste Google Drive link here"
                value={driveLink}
                onChange={(e) => setDriveLink(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            
            <div className="flex justify-end gap-3 pt-2">
              <button
                onClick={() => setShowUploadForm(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDriveLinkUpload(category);
                  setShowUploadForm(false);
                }}
                className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Upload
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );

  // Render PDF cards
  const renderPdfCards = (category) => {
    const filteredNotes = pslpNotes.filter((note) => note.category === category);
    
    if (filteredNotes.length === 0) {
      return renderEmptyState(category);
    }

    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 mt-4">
        {filteredNotes.map((note, index) => (
          <div key={note.id} className="flex flex-col">
            <motion.div 
              className="bg-white rounded-lg p-6 shadow-md cursor-pointer flex flex-col items-center relative hover:shadow-lg transition-shadow"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => openPdfModal(category, index)}
            >
              <div className="text-red-500 mb-4">
                <FaFilePdf size={48} />
              </div>
              <div className="text-center font-medium text-gray-800 break-words">
                {note.title.length > 30 ? `${note.title.substring(0, 30)}...` : note.title}
              </div>
              {user?.email === adminEmail && (
                <button 
                  className="absolute top-2 right-2 bg-red-500 text-white w-8 h-8 rounded-full flex items-center justify-center hover:bg-red-600 transition-colors"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNote(note);
                  }}
                >
                  <FaTrash size={14} />
                </button>
              )}
            </motion.div>
          </div>
        ))}
      </div>
    );
  };

  // PDF Modal component with Share and Download functionality
  const PdfModal = () => {
    if (!pdfModal.isOpen || !pdfModal.category) return null;
    
    const filteredNotes = pslpNotes.filter((note) => note.category === pdfModal.category);
    const currentPdf = filteredNotes[pdfModal.currentIndex];
    
    if (!currentPdf) return null;

    return (
      <AnimatePresence>
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={closePdfModal}
        >
          <motion.div
            className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] flex flex-col"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-medium truncate max-w-[80%]">{currentPdf.title}</h3>
              <div className="flex items-center gap-2">
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadPdf(currentPdf.link, currentPdf.title);
                  }}
                  className="p-2 rounded-full hover:bg-gray-100 text-blue-500 hover:text-blue-600"
                  title="Download PDF"
                >
                  <FaDownload size={18} />
                </button>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    shareViaWhatsApp(currentPdf.link, currentPdf.title);
                  }}
                  className="p-2 rounded-full hover:bg-gray-100 text-green-500 hover:text-green-600"
                  title="Share via WhatsApp"
                >
                  <FaWhatsapp size={18} />
                </button>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    copyPdfLink(currentPdf.link);
                  }}
                  className="p-2 rounded-full hover:bg-gray-100 text-gray-700 hover:text-blue-600"
                  title="Copy Link"
                >
                  <FaCopy size={18} />
                </button>
                <button 
                  onClick={closePdfModal}
                  className="p-2 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                  title="Close"
                >
                  <FaTimes size={20} />
                </button>
              </div>
            </div>
            
            <div className="flex-1 overflow-hidden relative">
              {/* Navigation arrows */}
              {filteredNotes.length > 1 && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigatePdf('prev');
                    }}
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white p-3 rounded-full shadow-md z-10 hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                  >
                    <FaChevronLeft size={24} />
                  </button>
                  
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigatePdf('next');
                    }}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white p-3 rounded-full shadow-md z-10 hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                  >
                    <FaChevronRight size={24} />
                  </button>
                </>
              )}
              
              <div className="h-full w-full overflow-auto">
                <iframe
                  src={currentPdf.directPreviewLink || `https://drive.google.com/file/d/${extractFileId(currentPdf.link)}/preview`}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  title={currentPdf.title}
                  className="min-h-[70vh]"
                  allow="autoplay"
                  loading="lazy"
                />
              </div>
            </div>
            
            <div className="p-4 border-t text-center text-sm text-gray-500">
              {pdfModal.currentIndex + 1} of {filteredNotes.length}
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    );
  };

  // Render video section
  const renderVideoSection = () => (
    <div className="mt-8">
      {user?.email === adminEmail && (
        <motion.div 
          className="flex flex-wrap gap-4 items-center mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <input
            type="text"
            placeholder="Video Title"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            className="flex-1 min-w-[200px] p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="YouTube URL"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            className="flex-1 min-w-[200px] p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <motion.button 
            onClick={handleAddVideo}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="flex items-center gap-2 px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
          >
            <FaYoutube /> Add Video
          </motion.button>
          <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2887238820446530"
            data-ad-slot="1502817186"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </motion.div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {videos.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-4 p-8 text-gray-500 col-span-full">
            <FaYoutube size={48} className="text-gray-300" />
            <p className="text-lg">No PSLP videos added yet</p>
            <motion.a
              href={uploadFormLink}
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors mt-4"
            >
              <FaExternalLinkAlt /> Request Video via Form
            </motion.a>
          </div>
        ) : (
          videos.map((video) => (
            <motion.div 
              key={video.id}
              className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow relative"
              whileHover={{ scale: 1.02 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="p-4 bg-gray-50 font-medium text-gray-800 truncate">{video.title}</div>
              <div className="relative pb-[56.25%] h-0 overflow-hidden">
                <iframe
                  src={video.link}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                  loading="lazy"
                />
              </div>
              {user?.email === adminEmail && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteVideo(video.id);
                  }}
                  className="absolute top-2 right-2 bg-red-500 text-white w-8 h-8 rounded-full flex items-center justify-center hover:bg-red-600 transition-colors"
                >
                  <FaTrash size={14} />
                </button>
              )}
            </motion.div>
          ))
        )}
      </div>
    </div>
  );

  // Accordion content
  const accordionContent = [
    <div key="1" className="p-4">
      <p>Basics: Probability and Statistical models, Sample Spaces and Events, Counting Techniques, Interpretations and Axioms of Probability, Unions of Events and Addition Rules, Conditional Probability, Intersections of Events and Multiplication and Total Probability Rules, Independence, Bayes' Theorem, Random Variables. Discrete and Continuous Random Variables and Distributions: Probability Distributions and Probability Mass / density Functions, Cumulative Distribution Functions, Mean and Variance of a Random Variable, Discrete and continuous Uniform Distribution, Binomial Distribution, Geometric and Negative Binomial Distributions, Hypergeometric Distribution, Poisson Distribution. Normal Distribution, Normal Approximation to the Binomial, and Poisson Distributions; Exponential Distribution, Erlang and Gamma Distributions, Weibull Distribution, Lognormal Distribution, Beta Distribution.</p>
    </div>,
    <div key="2" className="p-4">
      <p>Joint Probability Distributions for Two Random Variables, Conditional Probability Distributions and Independence, Joint Probability Distributions for Two Random Variables, Covariance and Correlation, Common Joint Distributions, Linear Functions of RandomVariables, General Functions of Random Variables, Moment-Generating Functions. Numerical Summaries of Data, Stem-and-Leaf Diagrams, Frequency Distributions and Histograms, Box Plots, Time Sequence Plots, Scatter Diagrams, Probability Plots. Point Estimation, Sampling Distributions and the Central Limit Theorem without proof, General Concepts of Point Estimation, Methods of Point Estimation, Statistical Intervals for a Single Sample.</p>
    </div>,
    <div key="3" className="p-4">
      <p>Hypotheses Testing for a SingleSample: Tests on the Mean of a Normal Distribution with Variance Known / Unknown, Tests on the Variance and Standard Deviationof a Normal Distribution, Tests on a Population Proportion, Testing for Goodness of Fit, Nonparametric tests (Signed, Wilcoxon), Similarly Statistical Inference forTwo Samples. Regression and Correlation: Linear Regression, Least Squares Estimators, Hypotheses testing for simple linear regression, Confidence Intervals, Adequacy of model, Correlation, Transformed Variables, Logistic Regression. Similarly, for multiple linear regression including aspects of MLR.</p>
    </div>,
    <div key="4" className="p-4">
      <p>Linear Programming: Introduction, formulation of problem, Graphical method, Canonical and Standard form of LPP, Simplex method, Duality concept, Dual simplex method, Transportation and Assignment problem.</p>
    </div>
  ];

  const tabData = [
    {
      id: 'syllabus',
      label: 'Syllabus',
      content: <Accordions accordionContent={accordionContent} />,
    },
    {
      id: 'notes',
      label: 'Notes',
      content: (
        <>
          {renderUploadButton('notes')}
          {renderPdfCards('notes')}
        </>
      ),
    },
    {
      id: 'important',
      label: 'Important Questions',
      content: (
        <>
          {renderUploadButton('important')}
          {renderPdfCards('important')}
        </>
      ),
    },
    {
      id: 'books',
      label: 'Books',
      content: (
        <>
          {renderUploadButton('books')}
          {renderPdfCards('books')}
        </>
      ),
    },
    {
      id: 'PYQ',
      label: 'PYQ',
      content: (
        <>
          {renderUploadButton('pyq')}
          {renderPdfCards('pyq')}
        </>
      ),
    },
    {
      id: 'youtube_playlist',
      label: 'Video Lectures',
      content: renderVideoSection(),
    },
  ];

  return (
    <section className="max-w-7xl mx-auto px-4 py-8">
      <motion.h1 
        className="text-4xl font-bold text-center text-white mb-8 md:mt-20"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Probability, Statistics and Linear Programming
      </motion.h1>
      
      {!user ? (
        <motion.button 
          onClick={handleGoogleLogin}
          className="flex items-center gap-2 mx-auto px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <FaSignInAlt /> Login with Google
          <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2887238820446530"
            data-ad-slot="1502817186"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </motion.button>
      ) : (
        <>
          <Tabs tabData={tabData} />
          <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2887238820446530"
            data-ad-slot="1502817186"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </>
      )}
      
      <PdfModal />
    </section>
  );
};

export default PSLP;
