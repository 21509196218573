import React, { useState } from 'react';
import { db } from './firebase'; 
import { collection, addDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contactus_img from '../assets/girl.png';
import Footer from './Footer';

const ContactUS = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.phone || !formData.message || !formData.email) {
      toast.error('Please fill in all required fields.', { position: 'top-right' });
      return;
    }

    try {
      await addDoc(collection(db, 'contactResponses'), formData);
      setFormData({ name: '', phone: '', message: '', email: '' });
      toast.success('Thank you for sending a message!', { position: 'top-right' });
    } catch (error) {
      console.error('Error submitting contact form: ', error);
      toast.error('Failed to send your message. Please try again.', { position: 'top-right' });
    }
  };

  return (
    <>
    <section className="contact_container" id="contactus">
      <ToastContainer />
      <motion.div
        className="Contact"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
              <motion.h3
  className=" contactusheading sm:text-[30px] font-bold text-center text-white"
  initial={{ opacity: 0, y: -30 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.5 }}
  style={{
    textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
    fontSize: "70px",
    fontFamily: "cursive",
    fontWeight: "bolder",
    textAlign: "center",
    marginTop: "90px",
  }}
>
  Contact Us
</motion.h3>
<div className='contactus'>

        <motion.div
          className="contact_div"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          <img  className='contactusimg' src={contactus_img} alt='contactUs'/>
          
          <form onSubmit={handleSubmit} className=" contactusform w-full max-w-md mt-8">
            <div className="contactusdisplay mb-6">
              <div className=" contactuslab w-1/2 mr-2">
                <label htmlFor="name" className=" contactuslabel block text-gray-700 text-sm font-bold mb-2">
                  Your Name
                </label>
                <motion.input
                  id="name"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  requiredEnter your 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                />
              </div>
              <div className="  w-1/2 ml-2">
                <label htmlFor="phone" className=" contactuslabel block text-gray-700 text-sm font-bold mb-2">
                  Your Phone Number
                </label>
                <motion.input
                  id="phone"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="tel"
                  name="phone"
                  placeholder="Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                />
              </div>
            </div>
            <div className="mb-6">
              <label htmlFor="email" className=" contactuslabel block text-gray-700 text-sm font-bold mb-2">
                Your Email
              </label>
              <motion.input
                id="email"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className=" contactuslabel block text-gray-700 text-sm font-bold mb-2">
                Your Message
              </label>
              <motion.textarea
                id="message"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="message"
                placeholder="Your message here..."
                value={formData.message}
                onChange={handleChange}
                required
                rows={4}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
              />
            </div>
            <motion.button
              type="submit"
              className=" contactusbutton bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              Submit
            </motion.button>
          </form>
        </motion.div>
        </div>
      </motion.div>

  
    </section>
    <Footer/>
    </>
  );
};

export default ContactUS;